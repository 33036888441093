import { post, get, patch, postFile, postAuth , removeToken, postNoAuth} from "../http";

export interface AuthRequest {
  username: string;
  password: string;
}

export const login = (auth: AuthRequest) => {
  return postAuth("/oauth/token", auth);
};
export const logout = (payload: string) => {
  return removeToken("/user/oauth/revoke-token", payload);
};
export const postpasswordChange = (payload) => post(`/user/change/password`, payload);

export const fetchsendPasswordRecoveryToken = (payload: string) => {
  return get("/password/recovery/token/send?userName="+payload);
};
export const fetchresetPassword = (payload: any) => {
  return get("/password/reset?password="+payload?.password+"&token="+payload?.token+"&userName="+payload?.userName);
};

export const instituteInfo = () => get("/institute/view");

export const fetchsingleInstituteBillUnpaidList = (payload) => postNoAuth(`/public/institute/un-paid/bill/list`, payload);