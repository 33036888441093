import { Button, Card, Col, DatePicker, Form, notification, Popconfirm, Row, Skeleton, Space, Table, Tooltip } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { DeleteFilled, DeleteOutlined, DownloadOutlined, FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectClassDepartment } from '../../../select/SelectClassDepartment';
import { SelectLedgerDFPSMulti } from '../../../select/SelectLedgerDFPSMulti';
import Text from 'antd/lib/typography/Text';
import { Excel } from 'antd-table-saveas-excel';
require('jspdf-autotable');

var getColumns = function () {
    return [
        { title: "Sales Invoice Id", dataKey: "salesInvoiceId" },
        { title: "Discount", dataKey: "discount" },
        { title: "Payable Amount", dataKey: "payableAmount" },
        { title: "Paid Amount", dataKey: "paidAmount" },
        { title: "Due Amount", dataKey: "dueAmount" },
        { title: "Sales Date", dataKey: "salesDate" },
    ];
};

let fd = '';
let td = '';
let totalamount = 0;
export default function InventorySalesView(props) {

    const salesInventoryItemViewList = useStoreState((state) => state.inventory.salesInventoryItemViewList);
    const fetchSalesInventoryItemViewList = useStoreActions((state) => state.inventory.fetchSalesInventoryItemViewList);
    const loading = useStoreState((state) => state.inventory.loading);
    const downloadSalesInventory = useStoreActions((state) => state.inventory.downloadSalesInventory);
    const deleteSalesInventory = useStoreActions((state) => state.inventory.deleteSalesInventory);
    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {

        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD")
        }
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        td = moment(value?.toDate).format("YYYY-MM-DD");
        fetchSalesInventoryItemViewList(postData);
        selectedRowKeys([]);
        selectedValue([]);
    };

    const deletePurchaseInventory = (value) => {

        let postData = {
            fromDate: fd,
            toDate: td,
            salesInvoiceId: value
        }

        deleteSalesInventory(postData);

    };


    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");

        var details = `Sales List from ${fd} to ${td} `;
        pdfDataL(doc, "");
        doc.text(details, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        let first = doc.autoTable?.previous;
        doc.autoTable(getColumns(), salesInventoryItemViewList, {
            startY: 45,
            showHeader: "firstPage",
            theme: 'grid',
            headerStyles: {
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                ledgerName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 75
                },
                debit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                },
                credit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                }
            },

        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setFontSize(8);
        doc.text(`Total Paid Amount: ${totalamount}`, 15, doc.autoTable.previous.finalY + 5);
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");
    }

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    const columns = [

        {
            title: 'Sales Date',
            dataIndex: 'salesDate',
            key: 'salesDate',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Sales Invoice Id',
            dataIndex: 'salesInvoiceId',
            key: 'salesInvoiceId',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Student ID',
            dataIndex: 'studentId',
            key: 'studentId',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Class',
            dataIndex: 'className',
            key: 'className',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Details',
            dataIndex: 'summary',
            key: 'summary',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Payable Amount',
            dataIndex: 'payableAmount',
            key: 'payableAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Due Amount',
            dataIndex: 'dueAmount',
            key: 'dueAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Action',
            key: 'purchaseId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Download">
                        <Button type="primary" onClick={() => downloadSalesInventory(record?.salesInvoiceId)} icon={<DownloadOutlined />} />
                    </Tooltip>

                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deletePurchaseInventory(record?.salesInvoiceId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        },

    ];
    const columns2 = [

        {
            title: 'Sales Date',
            dataIndex: 'salesDate',
            key: 'salesDate',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Sales InvoiceId',
            dataIndex: 'salesInvoiceId',
            key: 'salesInvoiceId',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Student ID',
            dataIndex: 'studentId',
            key: 'studentId',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Class',
            dataIndex: 'className',
            key: 'className',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Details',
            dataIndex: 'summary',
            key: 'summary',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Payable Amount',
            dataIndex: 'payableAmount',
            key: 'payableAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Due Amount',
            dataIndex: 'dueAmount',
            key: 'dueAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },

    ];





    return (
        <>
            <Card title="Inventory Sales List" >
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>

                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>

                    </Row>
                </Form>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />

                {salesInventoryItemViewList !== null &&
                    <>
                        <div className="datatable-responsive-demo">
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: salesInventoryItemViewList,
                                    pagination: true,
                                    bordered: true,
                                    rowKey: "purchaseId",

                                }}
                                mobileBreakPoint={768}
                            />
                        </div>
                        <Space size="middle" style={{ float: "right", marginTop: 15 }}>
                            < Button
                                type='primary'
                                icon={<FileExcelOutlined />}
                                onClick={() => {
                                    const excel: any = new Excel();
                                    excel
                                        .addSheet(`Sheet 1`)
                                        .addColumns(columns2)
                                        .addDataSource(salesInventoryItemViewList)
                                        .saveAs(`Paid collection from ${fd} to ${td}.xlsx`);
                                }}
                            >
                                Download List (Excel)
                            </ Button >
                            <Button type="primary" onClick={exportPdf} icon={<DownloadOutlined />} >Download List (PDF)</Button>

                        </Space>
                    </>
                }
            </Card>
        </>
    )
}
