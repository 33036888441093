import { Menu, MenuProps } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { AuditOutlined, CalculatorOutlined, ContainerOutlined, DashboardTwoTone, DeleteOutlined, DollarCircleOutlined, FileTextOutlined, FundProjectionScreenOutlined, FundViewOutlined, PartitionOutlined, SearchOutlined, SolutionOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons/lib';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useStoreActions, useStoreState } from '../../store/hooks/easyPeasy';
import { ROUTES } from "../../contents/routes";

type MenuItems = Required<MenuProps>['items'];

export function Sidebar() {
	const history = useHistory();
	const instituteInfo = useStoreState((state) => state.auth.instituteInfo);
	const fetchStudentListByClassConfigIdMenu = useStoreActions((state) => state.student.fetchStudentListByClassConfigIdMenu);

	useEffect(() => {
		fetchStudentListByClassConfigIdMenu();
	}, []);

	const [openkeys, setopenkeys] = useState<any>('')
	useEffect(() => {
		//fetchpartnerProfile();
		setpathValue(window.location.pathname.slice(1));
		if (window.location.pathname.slice(1) === '') {
			setpathValue('dashboard')
		}
		let temp = window.location.pathname.substr(window.location.pathname.lastIndexOf("-") + 1).toString();
		// console.log(temp)
		setopenkeys(temp)
	}, []);

	const [pathValue, setpathValue] = useState<any>("1")

	const [keys, setKeys] = useState<any>([]);

	const sideClick = useCallback((value) => {
		// console.log(value)
		setpathValue(value.key)
		setKeys(value.keyPath)
		localStorage.setItem('shebaSchool', JSON.stringify(value.keyPath));
		localStorage.setItem('shebaSchoolKey', (value.key));
		// history.push(value.key)
	}, [pathValue])

	useEffect(() => {
		let val: any = localStorage.getItem('shebaSchool');
		let val2: any = localStorage.getItem('shebaSchoolKey');
		if (val !== null) {
			setRefresh(JSON.parse(val))
			setKeys(JSON.parse(val))
		}
		if (val2 !== null) {
			setpathValue(val2)
		}
		setView(true)

	}, []);

	const [refreshKeys, setRefresh] = useState<any>([]);
	const [view, setView] = useState<any>(false);

	const onOpenChange = (value) => {
		setKeys(value)
	}
	useEffect(() => {
		let val: any = localStorage.getItem('openKeys');
		if (val !== null) {
			setRefresh(JSON.parse(val))
		}
		setView(true)
	}, []);

	function basicPremiumPackage(value) {
		if (instituteInfo?.packageId === 1 || instituteInfo?.packageId === 2) {
			history.push(value)
		} else {
			history.push(ROUTES.ERROR_PAGE)
		}
	};
	function sblPremiumPackage(value) {
		if (instituteInfo?.packageId === 2 || instituteInfo?.packageId === 3) {
			history.push(value)
		} else {
			history.push(ROUTES.ERROR_PAGE)
		}
	};

	function premiumPackage(value) {
		if (instituteInfo?.packageId === 2) {
			history.push(value)
		} else {
			history.push(ROUTES.ERROR_PAGE)
		}
	};

	function allPackage(value) {
		history.push(value)
	};

	function roleAdmin() {
		if (instituteInfo?.roleList?.includes('ROLE_ADMIN')) {
			return true
		} else return false
	}
	function roleCustomerSupport() {
		if (instituteInfo?.roleList?.includes('ROLE_CUSTOMER_SUPPORT')) {
			return true
		} else return false
	}

	function roleAdminAndOperator() {
		if ((instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR'))) {
			return true
		} else return false
	}
	function roleAdminAndAccountant() {
		if ((instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT'))) {
			return true
		} else return false
	}
	function roleAdminAndExamController() {
		if ((instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_EXAM_CONTROLLER'))) {
			return true
		} else return false
	}

	const menuItems: MenuItems = [
		{
			label: 'Dashboard',
			key: ROUTES.DEFAULT,
			icon: <DashboardTwoTone />,
			onClick: () => history.push(ROUTES.DEFAULT)
		},
		{
			label: 'Students',
			key: 'studentsMenu',
			icon: <PartitionOutlined />,
			children: [
				... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT') || instituteInfo?.roleList?.includes('ROLE_EXAM_CONTROLLER')) ? [{
					label: 'Settings',
					key: ROUTES.STUDENT_WRAPPER,
					icon: <FileTextOutlined />,
					onClick: () => history.push(ROUTES.STUDENT_WRAPPER)

				}] : [],
				... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT') || instituteInfo?.roleList?.includes('ROLE_EXAM_CONTROLLER')) ? [{
					label: 'Configuration',
					key: 'studentsConfigMenu',
					icon: <PartitionOutlined />,
					children: [
						{
							label: 'Class',
							key: ROUTES.STUDENT_CLASS_CONFIG,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.STUDENT_CLASS_CONFIG)
						},
						{
							label: 'Group',
							key: ROUTES.CLASS_SEMESTER_CONFIG,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.CLASS_SEMESTER_CONFIG)
						}
					]
				}] : [],
				... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT') || instituteInfo?.roleList?.includes('ROLE_EXAM_CONTROLLER')) ? [{
					label: 'Registration',
					key: 'studentsRegistrationMenu',
					icon: <PartitionOutlined />,
					children: [
						{
							label: 'General Registration',
							key: ROUTES.STUDENT_MULTIPLE_REGISTRATION,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.STUDENT_MULTIPLE_REGISTRATION)
						},
						{
							label: 'Excel Registration',
							key: ROUTES.STUDENT_MULTIPLE_REGISTRATION_EXCEL,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.STUDENT_MULTIPLE_REGISTRATION_EXCEL)
						}
					]
				}] : [],
				{
					label: 'Update',
					key: 'studentsUpdateMenu',
					icon: <PartitionOutlined />,
					children: [
						{
							label: 'Basic Info',
							key: ROUTES.STUDENT_BASIC_UPDATE,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.STUDENT_BASIC_UPDATE)

						},
						... (instituteInfo?.roleList?.includes('ROLE_TEACHER') || instituteInfo?.roleList?.includes('ROLE_ADMIN')) ? [{
							label: 'Profile',
							key: ROUTES.STUDENT_PROFILE_UPDATE,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.STUDENT_PROFILE_UPDATE)
						}] : [],
						... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT') || instituteInfo?.roleList?.includes('ROLE_EXAM_CONTROLLER')) ? [{
							label: 'Academic',
							key: ROUTES.STUDENT_ACADEMICINFO_UPDATE,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.STUDENT_ACADEMICINFO_UPDATE)
						}] : [],
						{
							label: 'ID',
							key: ROUTES.STUDENT_UPDATE_ID,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.STUDENT_UPDATE_ID)
						},
						{
							label: 'Photo',
							key: ROUTES.STUDENT_PHOTO_UPDATE,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.STUDENT_PHOTO_UPDATE)
						},
						{
							label: 'Address Info',
							key: ROUTES.STUDENT_ADDRESS_UPDATE,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.STUDENT_ADDRESS_UPDATE)
						},
						... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT') || instituteInfo?.roleList?.includes('ROLE_EXAM_CONTROLLER')) ? [{
							label: 'Enable Disable',
							key: ROUTES.STUDENT_ENABLED_DISABLED,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.STUDENT_ENABLED_DISABLED)
						}] : [],

					]
				},
				... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT') || instituteInfo?.roleList?.includes('ROLE_EXAM_CONTROLLER')) ? [{
					label: 'Migration',
					key: 'generalStudentsMigrationMenu',
					icon: <PartitionOutlined />,
					children: [
						{
							label: 'General',
							key: ROUTES.GENERAL_STUDENT_MIGRATION,
							icon: <FileTextOutlined />,
							onClick: () => {
								setTimeout(() => {
									history.push(ROUTES.GENERAL_STUDENT_MIGRATION);
									window.location.reload()
								}, 200);
							}
						},
						{
							label: 'Merit',
							key: ROUTES.MERIT_STUDENT_MIGRATION,
							icon: <FileTextOutlined />,
							onClick: () => {
								setTimeout(() => {
									history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.MERIT_STUDENT_MIGRATION)
									window.location.reload()
								}, 200);
							}
						},
						{
							label: 'Excel',
							key: ROUTES.EXCEL_STUDENT_MIGRATION,
							icon: <FileTextOutlined />,
							onClick: () => {
								setTimeout(() => {
									history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.EXCEL_STUDENT_MIGRATION)
									window.location.reload()
								}, 200);
							}
						}
					]
				}] : [],
				{
					label: 'Delete',
					key: 'studentsDeleteMenu',
					icon: <PartitionOutlined />,
					children: [
						{
							label: 'Registration Delete',
							key: ROUTES.STUDENT_REGISTRATION_DELETE,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.STUDENT_REGISTRATION_DELETE)
						},
					]
				},
				{
					label: 'ID Card',
					key: 'idCardTemplate',
					icon: <PartitionOutlined />,
					children: [
						{
							label: 'Student Info',
							key: ROUTES.STUDENT_ID_CARD_INFORMATION,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.STUDENT_ID_CARD_INFORMATION)
						},
						{
							label: 'Templates',
							key: ROUTES.STUDENT_ID_CARD_TEMPLATE,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.STUDENT_ID_CARD_TEMPLATE)
						},
						{
							label: 'Download',
							key: ROUTES.STUDENT_ID_CARD_TEMPLATE_DOWNLOAD,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.STUDENT_ID_CARD_TEMPLATE_DOWNLOAD)
						}
					]
				},
				... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT') || instituteInfo?.roleList?.includes('ROLE_EXAM_CONTROLLER')) ? [{
					label: 'Search',
					key: 'studentsSearchMenu',
					icon: <PartitionOutlined />,
					children: [
						{
							label: 'Single Student',
							key: ROUTES.STUDENT_SINGLE_SEARCH,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.STUDENT_SINGLE_SEARCH)
						},
					]
				}] : [],
				{
					label: 'Report',
					key: 'studentsReportMenu',
					icon: <PartitionOutlined />,
					children: [
						{
							label: 'Category Wise',
							key: ROUTES.STUDENT_REPORT_BASIC_INFO_CATEGORY_WISE_REPORT,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.STUDENT_REPORT_BASIC_INFO_CATEGORY_WISE_REPORT)
						},
						{
							label: 'Class Wise',
							key: ROUTES.CLASS_WISE_REPORT,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.CLASS_WISE_REPORT)
						},
						{
							label: 'Section Wise',
							key: ROUTES.SECTION_WISE_STUDENT_REPORT,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.SECTION_WISE_STUDENT_REPORT)
						},
						{
							label: 'Group Wise',
							key: ROUTES.GROUP_WISE_STUDENT_REPORT,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.GROUP_WISE_STUDENT_REPORT)
						},
						{
							label: 'Subject Wise',
							key: ROUTES.SUBJECT_WISE_SUMMARY_REPORT,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.SUBJECT_WISE_SUMMARY_REPORT)
						},
						{
							label: 'Section Summary',
							key: ROUTES.SECTION_WISE_SUMMARY_REPORT,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.SECTION_WISE_SUMMARY_REPORT)
						},

						{
							label: 'Class Summary',
							key: ROUTES.CLASS_WISE_SUMMARY_REPORT,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.CLASS_WISE_SUMMARY_REPORT)
						},

						{
							label: 'Gender Summary',
							key: ROUTES.GENDER_WISE_SUMMARY_REPORT,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.GENDER_WISE_SUMMARY_REPORT)
						},

						{
							label: 'Religion Summary',
							key: ROUTES.RELIGION_WISE_SUMMARY_REPORT,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.RELIGION_WISE_SUMMARY_REPORT)
						},


						{
							label: 'Overview',
							key: ROUTES.SECTION_OVERVIEW_REPORT,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.SECTION_OVERVIEW_REPORT)
						},
						{
							label: 'Profile',
							key: ROUTES.SECTION_PROFILE_REPORT,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.SECTION_PROFILE_REPORT)
						},
						{
							label: 'Taught List',
							key: ROUTES.TAUGHT_LIST_STUDENT_REPORT,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.TAUGHT_LIST_STUDENT_REPORT)
						},
					]
				},
				{
					label: 'International',
					key: 'international',
					icon: <PartitionOutlined />,
					children: [
						{
							label: 'Basic Info Update',
							key: ROUTES.STUDENT_BASIC_UPDATE_INTERNATIONAL,
							icon: <FileTextOutlined />,
							onClick: () => history.push(ROUTES.STUDENT_BASIC_UPDATE_INTERNATIONAL)
						},
						{
							label: 'Report',
							key: 'studentInternationalReport',
							icon: <FileTextOutlined />,
							children: [
								{
									label: 'Section Wise',
									key: ROUTES.SECTION_WISE_SUMMARY_REPORT_INTERNATIONAL,
									icon: <FileTextOutlined />,
									onClick: () => history.push(ROUTES.SECTION_WISE_SUMMARY_REPORT_INTERNATIONAL)
								}
							]
						},
					]
				},

			]
		},
		{
			label: 'Student Attendance',
			key: 'studentAttendance',
			icon: <PartitionOutlined />,
			children: [
				{
					label: 'Settings',
					key: 'studentAttendanceSetting',
					icon: <FileTextOutlined />,
					children: [
						{
							label: 'Period',
							key: ROUTES.INITIAL_SETUP_PERIOD,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.INITIAL_SETUP_PERIOD)
						},
						{
							label: 'Time Config',
							key: ROUTES.STUDENT_ATTENDANCE_PERIOD_TIME_CONFIGURATION,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.STUDENT_ATTENDANCE_PERIOD_TIME_CONFIGURATION)
						},
						{
							label: 'ID Mapping',
							key: ROUTES.STUDENT_DEVICE_ID_MAPPING,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.STUDENT_DEVICE_ID_MAPPING)
						},

						{
							label: 'Holiday',
							key: ROUTES.HOLIDAY,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.HOLIDAY)
						},
					]
				},
				{
					label: 'Attendance',
					key: 'studentAttendanceAttendance',
					icon: <FileTextOutlined />,
					children: [
						{
							label: 'Take',
							key: ROUTES.STUDENT_ATTENDANCE_INPUT,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.STUDENT_ATTENDANCE_INPUT)
						},
						{
							label: 'Update',
							key: ROUTES.STUDENT_ATTENDANCE_UPDATE,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.STUDENT_ATTENDANCE_UPDATE)
						},
						{
							label: 'Device',
							key: ROUTES.STUDENT_ATTENDANCE_DEVICE,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.STUDENT_ATTENDANCE_DEVICE)
						},
						{
							label: 'Leave Input',
							key: ROUTES.STUDENT_LEAVE_INPUT,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.STUDENT_LEAVE_INPUT)
						},
						{
							label: 'Exam Attendance',
							key: ROUTES.STUDENT_ATTENDANCE_EXAM,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.STUDENT_ATTENDANCE_EXAM)
						},
					]
				},
				{
					label: 'Report',
					key: 'studentAttendanceReport',
					icon: <FileTextOutlined />,
					children: [
						{
							label: 'Daily Details',
							key: ROUTES.DAILY_ATTENSION_REPORT_DAILY_SECTION,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.DAILY_ATTENSION_REPORT_DAILY_SECTION)
						},
						{
							label: 'Daily Summary',
							key: ROUTES.DAILY_ATTENSION_REPORT_SECTION_WISE_DAILY_SUMMARY,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.DAILY_ATTENSION_REPORT_SECTION_WISE_DAILY_SUMMARY)
						},
						{
							label: 'Monthly Details',
							key: ROUTES.STUDENT_ATTENDANCE_REPORT_MONTHLY_SECTION_DETAILS,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.STUDENT_ATTENDANCE_REPORT_MONTHLY_SECTION_DETAILS)
						},
						{
							label: 'Monthly Summary',
							key: ROUTES.STUDENT_ATTENDANCE_REPORT_MONTHLY_SECTION_SUMMARY,
							icon: <FileTextOutlined />,
							onClick: () => history.push(instituteInfo?.packageId === 3 ? ROUTES.ERROR_PAGE : ROUTES.STUDENT_ATTENDANCE_REPORT_MONTHLY_SECTION_SUMMARY)
						},
					]
				},
			]
		},

		... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR')) ? [
			{
				label: 'HRM',
				key: 'staffMenu',
				icon: <PartitionOutlined />,
				children: [
					{
						label: 'Settings',
						key: 'staffAttendanceSetting',
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.STAFF_SETTINGS)
					},
					{
						label: 'Registration',
						key: 'staff-registration',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'Single',
								key: ROUTES.STAFF_SINGLE_REGISTRATION,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.STAFF_SINGLE_REGISTRATION)
							},
							{
								label: 'Excel',
								key: ROUTES.STAFF_MULTIPLE_REGISTRATION,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.STAFF_MULTIPLE_REGISTRATION)
							},

						]
					},
					{
						label: 'Assign',
						key: 'staff-assign',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'Class Teacher',
								key: ROUTES.STAFF_ASSIGN,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.STAFF_ASSIGN)
							},
							{
								label: 'Subject Teacher',
								key: ROUTES.SUBJECT_TEACHER_ASSIGN,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.SUBJECT_TEACHER_ASSIGN)
							},
						]
					},
					{
						label: 'Update',
						key: 'staff-update',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'Basic info',
								key: ROUTES.STAFF_UPDATE,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.STAFF_UPDATE)
							},
						]
					},
					{
						label: 'ID Card',
						key: ROUTES.STAFF_ID_TEMP,
						icon: <PartitionOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.STAFF_ID_TEMP)
					},
					{
						label: 'Report',
						key: 'staff-report',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'HR List',
								key: ROUTES.STAFF_LIST,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.STAFF_LIST)
							},
						]
					},
				]
			},
		] : [],
		... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR')) ? [
			{
				label: 'HR Attendance',
				key: 'staffAttendance',
				icon: <PartitionOutlined />,
				children: [
					{
						label: 'Settings',
						key: 'staffAttendance-Settings',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'Time Config',
								key: ROUTES.HR_TIME_CONFIG,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.HR_TIME_CONFIG)
							},
							{
								label: 'ID Mapping',
								key: ROUTES.HR_TD_MAPPING,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.HR_TD_MAPPING)
							},
						]
					},
					{
						label: 'Attendance',
						key: 'staffAttendance-Attendance',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'Take',
								key: ROUTES.HR_ATTENDANCE_TAKE,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.HR_ATTENDANCE_TAKE)
							},
							{
								label: 'Update',
								key: ROUTES.HR_ATTENDANCE_UPDATE,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.HR_ATTENDANCE_UPDATE)
							},
							{
								label: 'Leave Input',
								key: ROUTES.HR_SINGLE_LEAVE,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.HR_SINGLE_LEAVE)
							},
							{
								label: 'File Upload',
								key: ROUTES.HR_FILE_UPLOAD,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.HR_FILE_UPLOAD)
							},
							{
								label: 'Machine Process',
								key: ROUTES.STAFF_ATTENDANCE_MACHINE,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.STAFF_ATTENDANCE_MACHINE)
							},
							{
								label: 'Remarks',
								key: ROUTES.HR_REMARK_DATE_WISE,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.HR_REMARK_DATE_WISE)
							},

						]
					},
					{
						label: 'Holiday',
						key: ROUTES.HOLIDAY,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.HOLIDAY)
					},
					{
						label: 'Report',
						key: 'staffAttendance-Report',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'In/Out Time',
								key: ROUTES.HR_REPORT_TIME_CONFIG,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.HR_REPORT_TIME_CONFIG)
							},
							{
								label: 'Daily Details',
								key: ROUTES.HR_REPORT_DATE_WISE,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.HR_REPORT_DATE_WISE)
							},
							{
								label: 'Monthly Details',
								key: ROUTES.HR_REPORT_MONTH_WISE,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.HR_REPORT_MONTH_WISE)
							},
						]
					}
				]
			}] : [],
		... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT')) ? [
			{
				label: 'General Accounts',
				key: 'generalAccountsMenu',
				icon: <PartitionOutlined />,
				children: [
					{
						label: 'Ledger List',
						key: ROUTES.LEDGER_LIST,
						icon: <FileTextOutlined />,
						onClick: () => premiumPackage(ROUTES.LEDGER_LIST)
					},
					{
						label: 'Transaction',
						key: 'transactionMenu',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'Receive',
								key: ROUTES.RECEIVE_TRN_TRANSACTION,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.RECEIVE_TRN_TRANSACTION)
							},
							{
								label: 'Payment',
								key: ROUTES.PAYMENT_TRN_TRANSACTION,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.PAYMENT_TRN_TRANSACTION)
							},
							{
								label: 'Contra',
								key: ROUTES.CONTRA_TRN_TRANSACTION,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.CONTRA_TRN_TRANSACTION)
							},
							{
								label: 'Journal',
								key: ROUTES.JOURNAL_TRN_TRANSACTION,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.JOURNAL_TRN_TRANSACTION)
							},
						]
					},
					{
						label: 'Voucher',
						key: 'gavoucher',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'Delete',
								key: ROUTES.DELETE_VOUCHER_GAVOUCHER,
								icon: <DeleteOutlined />,
								onClick: () => premiumPackage(ROUTES.DELETE_VOUCHER_GAVOUCHER)
							},
						]
					},
					{
						label: 'Report',
						key: 'gareport',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'Trial Balance',
								key: ROUTES.TRIAL_BALANCE_GAREPORT,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.TRIAL_BALANCE_GAREPORT)
							},
							{
								label: 'Balance Sheet',
								key: ROUTES.BALANCE_SHEET_GAREPORT,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.BALANCE_SHEET_GAREPORT)
							},
							{
								label: 'Income Statement',
								key: ROUTES.INCOME_STATEMENT_GAREPORT,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.INCOME_STATEMENT_GAREPORT)
							},
							{
								label: 'Journal',
								key: ROUTES.JOURNAL_GAREPORT,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.JOURNAL_GAREPORT)
							},
							{
								label: 'Funds Flow',
								key: ROUTES.FUNDS_FLOW_GAREPORT,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.FUNDS_FLOW_GAREPORT)
							},
							{
								label: 'Cash Summary',
								key: ROUTES.CASH_SUMMARY_GAREPORT,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.CASH_SUMMARY_GAREPORT)
							},
							{
								label: 'Accounts Book',
								key: ROUTES.ACCOUNT_BOOK,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.ACCOUNT_BOOK)
							},
							{
								label: 'User Wise',
								key: ROUTES.USER_WISE_TRAN,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.USER_WISE_TRAN)
							},
							{
								label: 'Voucher Template',
								key: ROUTES.VOUCHER_TEMPLATE,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.VOUCHER_TEMPLATE)
							},
						]
					},
				]
			}] : [],
		{
			label: 'Inventory',
			key: 'inventoryMenu',
			icon: <PartitionOutlined />,
			children: [
				{
					label: 'Settings',
					key: 'inventoryMenuSettings',
					icon: <PartitionOutlined />,
					children: [
						{
							label: 'Category',
							key: ROUTES.INVENTORY_CATEGORY,
							icon: <FileTextOutlined />,
							onClick: () => premiumPackage(ROUTES.INVENTORY_CATEGORY)
						},
						{
							label: 'Item',
							key: ROUTES.INVENTORY_ITEM,
							icon: <FileTextOutlined />,
							onClick: () => premiumPackage(ROUTES.INVENTORY_ITEM)
						},
						{
							label: 'Saler',
							key: ROUTES.INVENTORY_SALER,
							icon: <FileTextOutlined />,
							onClick: () => premiumPackage(ROUTES.INVENTORY_SALER)
						},
						{
							label: 'Ledger Configuration',
							key: ROUTES.INVENTORY_LEDGER_CONFIGURATION,
							icon: <FileTextOutlined />,
							onClick: () => premiumPackage(ROUTES.INVENTORY_LEDGER_CONFIGURATION)
						}
					]
				},
				{
					label: 'Purchase',
					key: ROUTES.INVENTORY_PURCHASE_SAVE,
					icon: <FileTextOutlined />,
					onClick: () => premiumPackage(ROUTES.INVENTORY_PURCHASE_SAVE)
				},
				{
					label: 'Purchase View',
					key: ROUTES.INVENTORY_PURCHASE_VIEW,
					icon: <FileTextOutlined />,
					onClick: () => premiumPackage(ROUTES.INVENTORY_PURCHASE_VIEW)
				},
				{
					label: 'Sales',
					key: ROUTES.INVENTORY_SALES_SAVE,
					icon: <FileTextOutlined />,
					onClick: () => premiumPackage(ROUTES.INVENTORY_SALES_SAVE)
				},
				{
					label: 'Sales View',
					key: ROUTES.INVENTORY_SALES_VIEW,
					icon: <FileTextOutlined />,
					onClick: () => premiumPackage(ROUTES.INVENTORY_SALES_VIEW)
				},
				{
					label: 'Stock In',
					key: ROUTES.INVENTORY_ITEM_RECORD_IN,
					icon: <FileTextOutlined />,
					onClick: () => premiumPackage(ROUTES.INVENTORY_ITEM_RECORD_IN)
				},
				{
					label: 'Stock Out',
					key: ROUTES.INVENTORY_ITEM_RECORD_OUT,
					icon: <FileTextOutlined />,
					onClick: () => premiumPackage(ROUTES.INVENTORY_ITEM_RECORD_OUT)
				},
				{
					label: 'Destroy',
					key: ROUTES.INVENTORY_ITEM_DESTROY_RECORD,
					icon: <FileTextOutlined />,
					onClick: () => premiumPackage(ROUTES.INVENTORY_ITEM_DESTROY_RECORD)
				},

			]
		},
		... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT') || instituteInfo?.roleList?.includes('ROLE_TEACHER')) ? [
			{
				label: 'Student Accounts',
				key: 'studentAccountMenu',
				icon: <PartitionOutlined />,
				children:
					[
						... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT')) ? [{
							label: 'Settings',
							key: 'sssettings',
							icon: <PartitionOutlined />,
							children: [
								... (instituteInfo?.packageId === 3) ? [{
									label: 'Create Ledger',
									key: "ledger-list-sbl",
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.LEDGER_LIST)
								}] : [],
								{
									label: 'Fee Head',
									key: ROUTES.FEE_HEAD,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.FEE_HEAD)
								},
								{
									label: 'Fee Sub Head',
									key: ROUTES.FEE_SUB_HEAD,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.FEE_SUB_HEAD)
								},
								{
									label: 'Fee Waiver',
									key: ROUTES.FEE_HEAD_WAIVER,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.FEE_HEAD_WAIVER)
								},
								{
									label: 'Money Recipt Template',
									key: ROUTES.MONEY_RECIPT_TEMPLATE,
									icon: <FileTextOutlined />,
									onClick: () => premiumPackage(ROUTES.MONEY_RECIPT_TEMPLATE)
								},
							]
						}] : [],

						... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT')) ? [{
							label: 'Configuration',
							key: 'ledger-configuration',
							icon: <PartitionOutlined />,
							children: [
								{
									label: 'Fee Sub Head',
									key: ROUTES.FEE_SUB_HEAD_LEDGER_CONFIGUARTION,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.FEE_SUB_HEAD_LEDGER_CONFIGUARTION)
								},
								{
									label: 'Fee Head Ledger',
									key: ROUTES.FEE_HEAD_LEDGER_CONFIGUARTION,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.FEE_HEAD_LEDGER_CONFIGUARTION)
								},
								{
									label: 'Fee Fine Ledger',
									key: ROUTES.FEE_FINE_LEDGER_CONFIGUARTION,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.FEE_FINE_LEDGER_CONFIGUARTION)
								},
								{
									label: 'Fee Amount ',
									key: ROUTES.FEE_AMOUNT_CONFIGUARTION,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.FEE_AMOUNT_CONFIGUARTION)
								},
								{
									label: 'Fee Amount View ',
									key: ROUTES.FEE_AMOUNT_VIEW,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.FEE_AMOUNT_VIEW)
								},
								{
									label: 'OFPS',
									key: ROUTES.OFPS_ACCOUNT_CONFIG,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.OFPS_ACCOUNT_CONFIG)
								},
								{
									label: 'Fee Waiver ',
									key: ROUTES.FEE_WAIVE_CONFIGUARTION,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.FEE_WAIVE_CONFIGUARTION)
								},
								{
									label: 'Fee Remove',
									key: ROUTES.FEE_DISCARD,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.FEE_DISCARD)
								},
								{
									label: 'Fee Subhead Remove',
									key: ROUTES.FEE_SUBHEAD_DISCARD,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.FEE_SUBHEAD_DISCARD)
								},
								{
									label: 'Fee Active Time',
									key: ROUTES.FEE_SUBHEAD_TIME_CONFIGUARTION,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.FEE_SUBHEAD_TIME_CONFIGUARTION)
								},

							]
						}] : [],
						/* {
							label: 'Attendance Fine',
							key: ROUTES.ATTENDANCE_FINE,
							icon: <FileTextOutlined />,
							onClick: () => sblPremiumPackage(ROUTES.ATTENDANCE_FINE)
						}, */
						{
							label: 'Individual Fee Assign',
							key: ROUTES.INDIVIDUAL_FEE_ASSIGN,
							icon: <FileTextOutlined />,
							onClick: () => allPackage(ROUTES.INDIVIDUAL_FEE_ASSIGN)
						},
						... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT')) ? [{
							label: 'Fees Collection',
							key: ROUTES.FEE_COLLECTION_MANUAL,
							icon: <FileTextOutlined />,
							onClick: () => sblPremiumPackage(ROUTES.FEE_COLLECTION_MANUAL)
						}] : [],
						... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT')) ? [{
							label: 'Delete',
							key: 'saDelete',
							icon: <DeleteOutlined />,
							children: [
								{
									label: 'Invoice',
									key: ROUTES.FEE_DELETE_INVOICE,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.FEE_DELETE_INVOICE)
								},
							]
						}] : [],
						... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT') || instituteInfo?.roleList?.includes('ROLE_TEACHER')) ? [{
							label: 'Report',
							key: 'saReport',
							icon: <PartitionOutlined />,
							children: [
								{
									label: 'Paid Invoice',
									key: 'saReportPaid',
									icon: <PartitionOutlined />,
									children: [
										... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT') || instituteInfo?.roleList?.includes('ROLE_TEACHER')) ? [{
											label: 'Section Paid',
											key: ROUTES.FEE_REPORT_SECTION_PAID_LIST,
											icon: <FileTextOutlined />,
											onClick: () => premiumPackage(ROUTES.FEE_REPORT_SECTION_PAID_LIST)
										}] : [],
										... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT')) ? [{
											label: 'Class Paid',
											key: ROUTES.FEE_REPORT_CLASS_PAID_LIST,
											icon: <FileTextOutlined />,
											onClick: () => premiumPackage(ROUTES.FEE_REPORT_CLASS_PAID_LIST)
										}] : [],
										... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT')) ? [{
											label: 'All Paid',
											key: ROUTES.FEE_REPORT_ALL_PAID_LIST,
											icon: <FileTextOutlined />,
											onClick: () => sblPremiumPackage(ROUTES.FEE_REPORT_ALL_PAID_LIST)
										}] : [],
									]

								},
								... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT') || instituteInfo?.roleList?.includes('ROLE_TEACHER')) ? [{
									label: 'Section Due',
									key: ROUTES.FEE_REPORT_SECTION_DUE,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.FEE_REPORT_SECTION_DUE)
								}] : [],
								... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT')) ? [{
									label: 'Monthly Due',
									key: ROUTES.FEE_REPORT_MONTHLY_DUE,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.FEE_REPORT_MONTHLY_DUE)
								}] : [],
								... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT')) ? [{
									label: 'Yearly Fee',
									key: ROUTES.YEARLY_FEE_DETAILS,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.YEARLY_FEE_DETAILS)
								}] : [],
								... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT') || instituteInfo?.roleList?.includes('ROLE_TEACHER')) ? [{
									label: 'Fee Head Collection ',
									key: ROUTES.FEE_REPORT_FEE_COLLECTION,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.FEE_REPORT_FEE_COLLECTION)
								}] : [],
								... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT') || instituteInfo?.roleList?.includes('ROLE_TEACHER')) ? [{
									label: 'Subhead Collection ',
									key: ROUTES.FEE_SUBHEAD_COLLECTION_REPORT,
									icon: <FileTextOutlined />,
									onClick: () => premiumPackage(ROUTES.FEE_SUBHEAD_COLLECTION_REPORT)
								}] : [],
								... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT') || instituteInfo?.roleList?.includes('ROLE_TEACHER')) ? [{
									label: 'Waiver Collection Summary',
									key: ROUTES.STUDENT_GIVEN_WAIVER,
									icon: <FileTextOutlined />,
									onClick: () => sblPremiumPackage(ROUTES.STUDENT_GIVEN_WAIVER)
								}] : [],

								{
									label: 'Ofps Statement',
									key: 'ofpsstatement',
									icon: <PartitionOutlined />,
									children: [
										... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT') || instituteInfo?.roleList?.includes('ROLE_TEACHER')) ? [{
											label: 'SPG',
											key: ROUTES.SPG_STATEMENT,
											icon: <FileTextOutlined />,
											onClick: () => allPackage(ROUTES.SPG_STATEMENT)
										}] : [],
										... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT')) ? [{
											label: 'Bkash',
											key: ROUTES.BKASH_STATEMENT,
											icon: <FileTextOutlined />,
											onClick: () => allPackage(ROUTES.BKASH_STATEMENT)
										}] : [],

										... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT')) ? [{
											label: 'Upay (PGW)',
											key: ROUTES.UPAY_PGW_STATEMENT,
											icon: <FileTextOutlined />,
											onClick: () => allPackage(ROUTES.UPAY_PGW_STATEMENT)
										}] : [],

										... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT')) ? [{
											label: 'Premier (SSL)',
											key: ROUTES.PREMIER_BANK_SSL_STATEMENT,
											icon: <FileTextOutlined />,
											onClick: () => allPackage(ROUTES.PREMIER_BANK_SSL_STATEMENT)
										}] : [],

									]

								},

								{
									label: 'Ofps Summary',
									key: 'ofpssummary',
									icon: <PartitionOutlined />,
									children: [
										... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT') || instituteInfo?.roleList?.includes('ROLE_TEACHER')) ? [{
											label: 'SPG',
											key: ROUTES.SPG_SUMMARY,
											icon: <FileTextOutlined />,
											onClick: () => allPackage(ROUTES.SPG_SUMMARY)
										}] : [],
										... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT')) ? [{
											label: 'Bkash',
											key: ROUTES.BKASH_SUMMARY,
											icon: <FileTextOutlined />,
											onClick: () => allPackage(ROUTES.BKASH_SUMMARY)
										}] : [],
										... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT')) ? [{
											label: 'Upay-PGW',
											key: ROUTES.UPAY_PGW_SUMMARY,
											icon: <FileTextOutlined />,
											onClick: () => allPackage(ROUTES.UPAY_PGW_SUMMARY)
										}] : [],



									]

								},

							]
						}] : [],
					]
			}] : [],
		... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT')) ? [
			{
				label: 'Payslip',
				key: 'paySlip',
				icon: <PartitionOutlined />,
				children: [
					{
						label: 'Create',
						key: 'payslipsettings',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'Single',
								key: ROUTES.PAYSLIP_CREATE_SINGLE,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.PAYSLIP_CREATE_SINGLE)
							},
							{
								label: 'Batch',
								key: ROUTES.PAYSLIP_CREATE_BATCH,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.PAYSLIP_CREATE_BATCH)
							},
						]
					},
					{
						label: 'Update',
						key: 'payslipupdate',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'Date',
								key: ROUTES.PAYSLIP_DATE_UPDATE,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.PAYSLIP_DATE_UPDATE)
							}
						]
					},
					{
						label: 'Collect',
						key: ROUTES.PAYSLIP_COLLECT,
						icon: <FileTextOutlined />,
						onClick: () => premiumPackage(ROUTES.PAYSLIP_COLLECT)
					},
					{
						label: 'Template',
						key: ROUTES.PAYSLIP_TEMPLATE,
						icon: <FileTextOutlined />,
						onClick: () => premiumPackage(ROUTES.PAYSLIP_TEMPLATE)
					},
				]
			}] : [],

		... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR')) ? [
			{
				label: 'Routine',
				key: 'routineMenu',
				icon: <PartitionOutlined />,
				children: [
					{
						label: 'Class Routine',
						key: ROUTES.CLASS_ROUTINE,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.CLASS_ROUTINE)
					},
					{
						label: 'Exam Routine',
						key: ROUTES.EXAM_ROUTINE,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.EXAM_ROUTINE)
					},
					{
						label: 'Exam Routine New',
						key: ROUTES.EXAM_ROUTINE2,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.EXAM_ROUTINE2)
					},
				]
			}] : [],

		... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR')) ? [
			{
				label: 'Sanmasik',
				key: 'sanmasikMenu',
				icon: <PartitionOutlined />,
				children: [
					{
						label: 'Class Configuration',
						key: ROUTES.SANMASIK_CLASS_INSTITUTE_CLASS_CONFIGURATION,
						icon: <FileTextOutlined />,
						onClick: () => premiumPackage(ROUTES.SANMASIK_CLASS_INSTITUTE_CLASS_CONFIGURATION)
					},
					{
						label: 'Student PI Input',
						key: ROUTES.SANMASIK_PI_INPUT,
						icon: <FileTextOutlined />,
						onClick: () => premiumPackage(ROUTES.SANMASIK_PI_INPUT)
					},

					{
						label: 'Transcript',
						key: ROUTES.SANMASIK_TRANSCRIPT_DOWNLOAD,
						icon: <FileTextOutlined />,
						onClick: () => premiumPackage(ROUTES.SANMASIK_TRANSCRIPT_DOWNLOAD)
					},

				]
			}] : [],


		... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_EXAM_CONTROLLER') || instituteInfo?.roleList?.includes('ROLE_TEACHER') || instituteInfo?.roleList?.includes('ROLE_OPERATOR')) ? [
			{
				label: 'Exam',
				key: 'examMenu',
				icon: <PartitionOutlined />,
				children: [
					... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_EXAM_CONTROLLER')) ? [
						{
							label: 'Settings',
							key: ROUTES.EXAM_SETTING,
							icon: <FileTextOutlined />,
							onClick: () => basicPremiumPackage(ROUTES.EXAM_SETTING)
						},
						{
							label: 'Configuration',
							key: 'configuration',
							icon: <PartitionOutlined />,
							children: [
								{
									label: 'Subject',
									key: ROUTES.EXAM_SUBJECT_CONFIGURATION,
									icon: <FileTextOutlined />,
									onClick: () => basicPremiumPackage(ROUTES.EXAM_SUBJECT_CONFIGURATION)
								},
								{
									label: 'Exam',
									key: ROUTES.EXAM_CONFIGURATION,
									icon: <FileTextOutlined />,
									onClick: () => basicPremiumPackage(ROUTES.EXAM_CONFIGURATION)
								},
								{
									label: 'Mark',
									key: ROUTES.EXAM_MARK_CONFIGURE,
									icon: <FileTextOutlined />,
									onClick: () => basicPremiumPackage(ROUTES.EXAM_MARK_CONFIGURE)
								},
								{
									label: 'Grand Final',
									key: ROUTES.EXAM_GRANDFINAL_MARK_CONFIGURE,
									icon: <FileTextOutlined />,
									onClick: () => basicPremiumPackage(ROUTES.EXAM_GRANDFINAL_MARK_CONFIGURE)
								},
								{
									label: '4th Subject',
									key: ROUTES.EXAM_FOUR_SUBJECT_ASSIGN,
									icon: <FileTextOutlined />,
									onClick: () => basicPremiumPackage(ROUTES.EXAM_FOUR_SUBJECT_ASSIGN)
								},
								{
									label: 'Admit Card Instruction',
									key: ROUTES.ADMIT_CARD_INSTRUCTION,
									icon: <FileTextOutlined />,
									onClick: () => basicPremiumPackage(ROUTES.ADMIT_CARD_INSTRUCTION)
								},
								{
									label: 'Admit Card Download Condition',
									key: ROUTES.ADMIT_CARD_DOWNLOAD_CONDITION,
									icon: <FileTextOutlined />,
									onClick: () => basicPremiumPackage(ROUTES.ADMIT_CARD_DOWNLOAD_CONDITION)
								},
								{
									label: 'Result Publish',
									key: ROUTES.EXAM_RESULT_PUBLISH,
									icon: <FileTextOutlined />,
									onClick: () => basicPremiumPackage(ROUTES.EXAM_RESULT_PUBLISH)
								},
								{
									label: 'Template',
									key: ROUTES.EXAM_TEMPLATE,
									icon: <FileTextOutlined />,
									onClick: () => basicPremiumPackage(ROUTES.EXAM_TEMPLATE)
								},
							]
						}] : [],
					{
						label: 'Admit/Seat Plan',
						key: ROUTES.ADMIT_CARD_SEAT_PLAN,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.ADMIT_CARD_SEAT_PLAN)
					},
					{
						label: 'Mark Input',
						key: ROUTES.EXAM_MARK_INPUT + 5,
						icon: <FileTextOutlined />,
						children: [
							{
								label: 'Class Teacher',
								key: ROUTES.EXAM_MARK_INPUT,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_MARK_INPUT)
							},
							{
								label: 'Subject Teacher',
								key: ROUTES.EXAM_MARK_INPUT_SUBJECT,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_MARK_INPUT_SUBJECT)
							},
						]

					},
					{
						label: 'Mark Update',
						key: ROUTES.EXAM_MARK_UPDATE + 6,
						icon: <FileTextOutlined />,
						children: [
							{
								label: 'Class Teacher',
								key: ROUTES.EXAM_MARK_UPDATE,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_MARK_UPDATE)
							},
							{
								label: 'Subject Teacher',
								key: ROUTES.EXAM_MARK_UPDATE_SUBJECT,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_MARK_UPDATE_SUBJECT)
							},
						]
					},
					{
						label: 'Mark Process',
						key: ROUTES.EXAM_MARK_PROCESS_CLASS + '44',
						icon: <FileTextOutlined />,
						children: [
							{
								label: 'Class Wise',
								key: ROUTES.EXAM_MARK_PROCESS_CLASS,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_MARK_PROCESS_CLASS)
							},
						]
					},
				
					... (instituteInfo?.roleList?.includes('ROLE_ADMIN')) ? [{
						label: 'Mark Delete',
						key: ROUTES.EXAM_MARK_DELETE,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.EXAM_MARK_DELETE)
					}] : [],
					{
						label: 'Mark Problem Search',
						key: ROUTES.EXAM_MARK_PROBLEM,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.EXAM_MARK_PROBLEM)
					},
					... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR') || instituteInfo?.roleList?.includes('ROLE_EXAM_CONTROLLER')) ? [
						{
							label: 'Process',
							key: 'processExam',
							icon: <PartitionOutlined />,
							children: [
								{
									label: 'General Exam',
									key: ROUTES.EXAM_GENERAL_RESULT_PROCESS,
									icon: <FileTextOutlined />,
									onClick: () => basicPremiumPackage(ROUTES.EXAM_GENERAL_RESULT_PROCESS)
								},
								{
									label: 'Grand Final',
									key: ROUTES.EXAM_GENERAL_RESULT_PROCESS_GRAND_FINAL,
									icon: <FileTextOutlined />,
									onClick: () => basicPremiumPackage(ROUTES.EXAM_GENERAL_RESULT_PROCESS_GRAND_FINAL)
								},
								{
									label: 'Merit Position',
									key: ROUTES.EXAM_GENERAL_RESULT_MERIT_POSITION,
									icon: <FileTextOutlined />,
									onClick: () => basicPremiumPackage(ROUTES.EXAM_GENERAL_RESULT_MERIT_POSITION)
								},
								{
									label: 'Remarks',
									key: ROUTES.EXAM_REMARKS_GIVE,
									icon: <FileTextOutlined />,
									onClick: () => basicPremiumPackage(ROUTES.EXAM_REMARKS_GIVE)
								},
							]
						}] : [],

					{
						label: 'Mark Sheet',
						key: 'markSheetKey',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'General Exam',
								key: ROUTES.EXAM_GENERAL_MARKSHEET_DOWNLOAD,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_GENERAL_MARKSHEET_DOWNLOAD)
							},
							{
								label: 'Grand Final',
								key: ROUTES.EXAM_GRAND_MARKSHEET_DOWNLOAD,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_GRAND_MARKSHEET_DOWNLOAD)
							},
						]
					},
					{
						label: 'Tabulation',
						key: ROUTES.EXAM_GENERAL_TEBULATION_SHEET,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.EXAM_GENERAL_TEBULATION_SHEET)
					},
					{
						label: 'Report',
						key: 'examReportmenu',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'Un-Assign Mark',
								key: ROUTES.EXAM_REPORT_SECTION_WISE_UNINPUTTED_SUBJECTS,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_REPORT_SECTION_WISE_UNINPUTTED_SUBJECTS)
							},
							{
								label: 'Section Result',
								key: ROUTES.EXAM_REPORT_SECTION_RESULT,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_REPORT_SECTION_RESULT)
							},
							{
								label: 'Class Result',
								key: ROUTES.EXAM_REPORT_CLASS_RESULT,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_REPORT_CLASS_RESULT)
							},
							{
								label: 'Class Merit',
								key: ROUTES.EXAM_REPORT_CLASS_WISE_MERIT_LIST,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_REPORT_CLASS_WISE_MERIT_LIST)
							},
							{
								label: 'Section Merit',
								key: ROUTES.EXAM_REPORT_SECTION_WISE_MERIT_LIST,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_REPORT_SECTION_WISE_MERIT_LIST)
							},
							{
								label: 'Group Merit',
								key: ROUTES.EXAM_REPORT_GROUP_WISE_MERIT_LIST,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_REPORT_GROUP_WISE_MERIT_LIST)
							},
							{
								label: 'Class Failed',
								key: ROUTES.EXAM_REPORT_CLASS_FAILED,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_REPORT_CLASS_FAILED)
							},
							{
								label: 'Section Failed',
								key: ROUTES.EXAM_REPORT_SECTION_FAILED,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_REPORT_SECTION_FAILED)
							},
							{
								label: 'Grading Summary',
								key: ROUTES.EXAM_REPORT_SECTION_WISE_GRADING_SUMMARY,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_REPORT_SECTION_WISE_GRADING_SUMMARY)
							},
							{
								label: 'Subject Grading Summary',
								key: ROUTES.EXAM_REPORT_SUBJECT_WISE_GRADING_SUMMARY,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_REPORT_SUBJECT_WISE_GRADING_SUMMARY)
							},
							{
								label: 'Pass Fail Summary',
								key: ROUTES.EXAM_REPORT_SECTION_WISE_PASS_FAIL_SUMMARY,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_REPORT_SECTION_WISE_PASS_FAIL_SUMMARY)
							},
							{
								label: 'Seat Plan',
								key: ROUTES.SEAT_PLAN,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.SEAT_PLAN)
							},
							{
								label: 'Attendance Blank Sheet',
								key: ROUTES.EXAM_BLANK_SHEET,
								icon: <FileTextOutlined />,
								onClick: () => basicPremiumPackage(ROUTES.EXAM_BLANK_SHEET)
							},

						]
					},
				]

			}] : [],

			... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_EXAM_CONTROLLER') || instituteInfo?.roleList?.includes('ROLE_TEACHER') || instituteInfo?.roleList?.includes('ROLE_OPERATOR')) ? [
				{
					label: 'Special Exam',
					key: 'specialExam',
					icon: <PartitionOutlined />,
					children: [
						{
							label: 'Mark Input',
							key: ROUTES.EXAM_MARK_INPUT_SPECIAL,
							icon: <FileTextOutlined />,
							onClick: () => basicPremiumPackage(ROUTES.EXAM_MARK_INPUT_SPECIAL)
						},
						{
							label: 'Mark Update',
							key: ROUTES.EXAM_MARK_UPDATE_SPECIAL,
							icon: <FileTextOutlined />,
							onClick: () => basicPremiumPackage(ROUTES.EXAM_MARK_UPDATE_SPECIAL)
						},
						{
							label: 'Mark Sheet',
							key: ROUTES.EXAM_MARK_SHEET_SPECIAL,
							icon: <FileTextOutlined />,
							onClick: () => basicPremiumPackage(ROUTES.EXAM_MARK_SHEET_SPECIAL)
						},
						
	
					]
				},
			] : [],

		... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR')) ? [
			{
				label: 'SMS',
				key: 'smsMenu',
				icon: <PartitionOutlined />,
				children: [
					{
						label: 'Dynamic SMS',
						key: ROUTES.SMS_SEND_DYNAMIC,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.SMS_SEND_DYNAMIC)
					},
					{
						label: 'Staff SMS',
						key: ROUTES.STAFF_SMS,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.STAFF_SMS)
					},
					{
						label: 'Student SMS',
						key: 'studentSms',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'Class SMS',
								key: ROUTES.STUDENT_CLASSWISE_SMS,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.STUDENT_CLASSWISE_SMS)
							},
							{
								label: 'Section SMS',
								key: ROUTES.STUDENT_SECTIONWISE_SMS,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.STUDENT_SECTIONWISE_SMS)
							},
							{
								label: 'Instiute SMS',
								key: ROUTES.STUDENT_INSTIUTE_SMS,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.STUDENT_INSTIUTE_SMS)
							},
							{
								label: 'Section Due',
								key: ROUTES.SECTION_DUE_SMS,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.SECTION_DUE_SMS)
							},
							{
								label: 'Fee Paid SMS',
								key: ROUTES.FEE_PAID_SMS,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.FEE_PAID_SMS)
							},
							{
								label: 'Exam Result',
								key: ROUTES.SEND_SMS_EXAM_RESULT,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.SEND_SMS_EXAM_RESULT)
							},
							{
								label: 'Registration SMS',
								key: ROUTES.REGISTRATION_SMS,
								icon: <FileTextOutlined />,
								onClick: () => premiumPackage(ROUTES.REGISTRATION_SMS)
							},

						]
					},
					{
						label: 'Templates',
						key: ROUTES.SMS_TEMPLATE,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.SMS_TEMPLATE)
					},
					{
						label: 'SMS Purchase',
						key: ROUTES.SMS_PURCHASE,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.SMS_PURCHASE)
					},
					{
						label: 'SMS Purchase Log',
						key: ROUTES.SMS_PURCHASE_LOG,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.SMS_PURCHASE_LOG)
					},

					{
						label: 'Report',
						key: 'smsreportMenu',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'General',
								key: ROUTES.GENERAL_SMS_LIST,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.GENERAL_SMS_LIST)
							},
							{
								label: 'Staff',
								key: ROUTES.STAFF_SMS_LIST,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.STAFF_SMS_LIST)
							},
							{
								label: 'Student',
								key: ROUTES.STUDENT_SMS_LIST,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.STUDENT_SMS_LIST)
							},
						]
					},
				]
			},
		] : [],

		... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR')) ? [
			{
				label: 'Online Admission',
				key: 'onlineAdmission',
				icon: <PartitionOutlined />,
				children: [

					{
						label: 'General List',
						key: ROUTES.ONLINE_ADMISSION_GENERAL_REPORT,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.ONLINE_ADMISSION_GENERAL_REPORT)
					},

					{
						label: 'Paid Unpaid Info',
						key: ROUTES.ONLINE_ADMISSION_REPORT_PAID_UNPAID,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.ONLINE_ADMISSION_REPORT_PAID_UNPAID)
					},
					{
						label: 'Student Registration',
						key: ROUTES.ONLINE_ADMISSION_STUDENT_REGISTRATION,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.ONLINE_ADMISSION_STUDENT_REGISTRATION)
					},
					{
						label: 'Info Edit',
						key: ROUTES.ONLINE_ADMISSION_INFO_EDIT,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.ONLINE_ADMISSION_INFO_EDIT)
					},

				]
			},
		] : [],


		... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR')) ? [
			{
				label: 'Alumni',
				key: 'alumni',
				icon: <PartitionOutlined />,
				children: [

					{
						label: 'Applicant List',
						key: ROUTES.ALUMNI_STUDENT_VIEW,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.ALUMNI_STUDENT_VIEW)
					},

				]
			},
		] : [],

		... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR')) ? [
			{
				label: 'TC/Testimonials',
				key: 'tcTestimonial',
				icon: <PartitionOutlined />,
				children: [
					{
						label: 'Testimonials',
						key: ROUTES.TESTIMONIALS,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.TESTIMONIALS)
					},
					{
						label: 'TC',
						key: ROUTES.TRANSFER_CERTIFICATE,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.TRANSFER_CERTIFICATE)
					},

				]
			},
		] : [],



		... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR')) ? [
			{
				label: 'Library',
				key: 'library',
				icon: <PartitionOutlined />,
				children: [
					{
						label: 'Book Category',
						key: ROUTES.BOOKCATEGORY,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.BOOKCATEGORY)
					},
					{
						label: 'Add Writer',
						key: ROUTES.ADDWRITER,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.ADDWRITER)
					},

					{
						label: 'Add Co-Writer',
						key: ROUTES.ADDCOWRITER,
						icon: <FileTextOutlined />,
						onClick: () => basicPremiumPackage(ROUTES.ADDCOWRITER)
					},


				]
			},
		] : [],

		...(instituteInfo?.roleList?.includes("ROLE_ADMIN") || instituteInfo?.roleList?.includes("ROLE_OPERATOR")) ? [

			{
				key: "payrollMenu",
				icon: <PartitionOutlined />,
				label: "Payroll",
				children: [
					{
						key: "payrollSetiings",
						icon: <PartitionOutlined />,
						label: "Settings",
						children: [
							{
								key: ROUTES.SETTINGS_PAYROLL_ADDITION,
								icon: <ContainerOutlined />,
								label: "Addition",
								onClick: () => premiumPackage(ROUTES.SETTINGS_PAYROLL_ADDITION)
							},
							{
								key: ROUTES.SETTINGS_PAYROLL_DEDUCTION,
								icon: <ContainerOutlined />,
								label: "Deduction",
								onClick: () => premiumPackage(ROUTES.SETTINGS_PAYROLL_DEDUCTION)
							},
							{
								key: ROUTES.SETTINGS_PAYROLL_GRADE,
								icon: <ContainerOutlined />,
								label: "Grade",
								onClick: () => premiumPackage(ROUTES.SETTINGS_PAYROLL_GRADE)
							},
							{
								key: ROUTES.BANK_ADVISE_CONTENT,
								icon: <ContainerOutlined />,
								label: "Bank Advise Content",
								onClick: () => premiumPackage(ROUTES.BANK_ADVISE_CONTENT)
							},
							{
								key: ROUTES.EMPLOYEE_BANK_ACCOUNT,
								icon: <ContainerOutlined />,
								label: "Employee Bank Account",
								onClick: () => premiumPackage(ROUTES.EMPLOYEE_BANK_ACCOUNT)
							},
							{
								key: ROUTES.SALARY_DEFAULT_CONFIG,
								icon: <ContainerOutlined />,
								label: "Salary Default Configuration",
								onClick: () => premiumPackage(ROUTES.SALARY_DEFAULT_CONFIG)
							}
						]
					},
					{
						key: "payrollConfig",
						icon: <PartitionOutlined />,
						label: "Configuration",
						children: [
							{
								key: ROUTES.EMPLOYEE_SALARY_CONFIGUARION,
								icon: <ContainerOutlined />,
								label: "Employee Salary",
								onClick: () => premiumPackage(ROUTES.EMPLOYEE_SALARY_CONFIGUARION)
							}
						]
					},
					{
						key: ROUTES.SALARY_SHEET_CREATE,
						icon: <ContainerOutlined />,
						label: "Salary Sheet Create",
						onClick: () => premiumPackage(ROUTES.SALARY_SHEET_CREATE)
					},
					{
						key: ROUTES.SALARY_PAYMENT,
						icon: <ContainerOutlined />,
						label: "Salary Payment",
						onClick: () => premiumPackage(ROUTES.SALARY_PAYMENT)
					}
				]
			},

		] : [],

		... (instituteInfo?.roleList?.includes('ROLE_ADMIN')) ? [
			{
				label: 'Master Settings',
				key: 'mastersettings',
				icon: <PartitionOutlined />,
				children: [
					{
						label: 'Institute Update',
						key: ROUTES.INSTITUTE_UPDATE,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.INSTITUTE_UPDATE)
					},
					{
						label: 'Default Signature',
						key: ROUTES.DEFAULT_SIGNATURE,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.DEFAULT_SIGNATURE)
					},
					{
						label: 'User List',
						key: ROUTES.MASTER_USER_LIST,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.MASTER_USER_LIST)
					},


					{
						label: 'Billing',
						key: 'newBill',
						icon: <PartitionOutlined />,

						children: [
							{
								label: 'Unpaid Bill',
								key: ROUTES.SINGLE_INSTITUTE_PAYABLE_BILL,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.SINGLE_INSTITUTE_PAYABLE_BILL)
							},
							{
								label: 'Paid Bill',
								key: ROUTES.SINGLE_INSTITUTE_PAID_BILL,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.SINGLE_INSTITUTE_PAID_BILL)
							},
						]

					},



				]
			},
		] : [],

		... (instituteInfo?.roleList?.includes('ROLE_CUSTOMER_SUPPORT')) ? [
			{
				label: 'Customer Support',
				key: 'customerSupport',
				icon: <PartitionOutlined />,
				children: [
					{
						label: 'Goto Institute',
						key: ROUTES.INSTITUTE_JUMP,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.INSTITUTE_JUMP)
					},
					{
						label: 'Institute List',
						key: ROUTES.INSTITUTE_LIST,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.INSTITUTE_LIST)
					},
					{
						label: 'Group Configuration',
						key: ROUTES.GROUP_CONFIG_CUSTOMER_SUPPORT,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.GROUP_CONFIG_CUSTOMER_SUPPORT)
					},
					{
						label: 'OFPS (SBL) Institute List',
						key: ROUTES.OFPS_INSTITUTE_LIST,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.OFPS_INSTITUTE_LIST)
					},
					{
						label: 'OFPS (Bkash) Institute List',
						key: ROUTES.BKASH_OFPS_INSTITUTE_LIST,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.BKASH_OFPS_INSTITUTE_LIST)
					},
					{
						label: 'Bkash OFPS Configuration',
						key: ROUTES.BKASH_OFPS_CONFIGURATION_SAVE,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.BKASH_OFPS_CONFIGURATION_SAVE)
					},
					{
						label: 'Partner Infos',
						key: ROUTES.PARTNER_INFO,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.PARTNER_INFO)
					},

					{
						label: 'Global SMS Template',
						key: ROUTES.GLOBAL_SMS_TEMPLATE,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.GLOBAL_SMS_TEMPLATE)
					},

					{
						label: 'Sheba Default',
						key: ROUTES.SHEBA_SCHOOL_INFO,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.SHEBA_SCHOOL_INFO)
					},

					{
						label: 'Student Count',
						key: ROUTES.INSTITUTE_STUDENT_COUNT,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.INSTITUTE_STUDENT_COUNT)
					},
					{
						label: 'Grade Update',
						key: ROUTES.EXAM_GRADE_UPDATE,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.EXAM_GRADE_UPDATE)
					},
					{
						label: 'OFPS Problem',
						key: 'customerSupportOFPS',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'SPG',
								key: ROUTES.OFPS_PROBLEM,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.OFPS_PROBLEM)
							},

							{
								label: 'Bkash',
								key: ROUTES.BKASH_OFPS_PROBLEM,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.BKASH_OFPS_PROBLEM)
							},

							{
								label: 'Upay (PGW)',
								key: ROUTES.UPAY_PGW_OFPS_PROBLEM,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.UPAY_PGW_OFPS_PROBLEM)
							},
							... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_ACCOUNTANT')) ? [{
								label: 'Upay (Pay Bill)',
								key: ROUTES.UPAY_PAY_BILL,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.UPAY_PAY_BILL)
							}] : [],
							{
								label: 'Bkash Refund',
								key: ROUTES.BKASH_REFUND_VIEW,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.BKASH_REFUND_VIEW)
							},
						]
					},
					{
						label: 'Admission Problem',
						key: 'admissionProblem',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'SPG View',
								key: ROUTES.ONLINE_ADMISSION_SPG_PROBLEM_VIEW,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.ONLINE_ADMISSION_SPG_PROBLEM_VIEW)
							},
						]
					},

					{
						label: 'Alumni Problem',
						key: 'alumnispgproblem',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'SPG View',
								key: ROUTES.ALUMNI_SPG_PROBLEM_VIEW,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.ALUMNI_SPG_PROBLEM_VIEW)
							},
						]
					},

					{
						label: 'Migration Change',
						key: ROUTES.MIGRATION_CHANGE,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.MIGRATION_CHANGE)
					},
					{
						label: 'Partner',
						key: ROUTES.PARTNER_INFORMATION,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.PARTNER_INFORMATION)
					},
					{
						label: 'Sanmasik',
						key: 'sanmasikmenu',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'Settings',
								key: 'sanmasikmenusetting',
								icon: <PartitionOutlined />,
								children: [
									{
										label: 'Class',
										key: ROUTES.SANMASIK_CLASS,
										icon: <FileTextOutlined />,
										onClick: () => allPackage(ROUTES.SANMASIK_CLASS)
									},
									{
										label: 'Subject',
										key: ROUTES.SANMASIK_SUBJECT,
										icon: <FileTextOutlined />,
										onClick: () => allPackage(ROUTES.SANMASIK_SUBJECT)
									},
									{
										label: 'Competency',
										key: ROUTES.SANMASIK_COMPETENCY,
										icon: <FileTextOutlined />,
										onClick: () => allPackage(ROUTES.SANMASIK_COMPETENCY)
									},
									{
										label: 'PI Info',
										key: ROUTES.SANMASIK_PI,
										icon: <FileTextOutlined />,
										onClick: () => allPackage(ROUTES.SANMASIK_PI)
									},
									{
										label: 'Chapter Info',
										key: ROUTES.SANMASIK_CHAPTER,
										icon: <FileTextOutlined />,
										onClick: () => allPackage(ROUTES.SANMASIK_CHAPTER)
									},
								]
							},
						]
					},

					{
						label: 'Device Settings',
						key: 'deviceSettings',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'Zkteco',
								key: ROUTES.ZKTECO_DEVICE_SETTINGS,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.ZKTECO_DEVICE_SETTINGS)
							},
						]
					},

					
				]
			},
		] : [],

		// ... (instituteInfo?.roleList?.includes('ROLE_ADMIN') || instituteInfo?.roleList?.includes('ROLE_OPERATOR')) ? [
		// 	{

		// 		label: 'Online Admission',
		// 		key: 'onlineAdmission',
		// 		icon: <PartitionOutlined />,
		// 		children: [
		// 			{
		// 				label: 'Section Wise',
		// 				key: ROUTES.SECTION_WISE_ONLINE_ADMISSION,
		// 				icon: <FileTextOutlined />,
		// 				onClick: () => basicPremiumPackage(ROUTES.SECTION_WISE_ONLINE_ADMISSION)
		// 			},
		// 			{
		// 				label: 'Group Wise',
		// 				key: ROUTES.GROUP_WISE_ONLINE_ADMISSION,
		// 				icon: <FileTextOutlined />,
		// 				onClick: () => basicPremiumPackage(ROUTES.GROUP_WISE_ONLINE_ADMISSION)
		// 			},
		// 			{
		// 				label: 'Payment Status',
		// 				key: ROUTES.PAYMENT_STATUS_WISE_ONLINE_ADMISSION,
		// 				icon: <FileTextOutlined />,
		// 				onClick: () => basicPremiumPackage(ROUTES.PAYMENT_STATUS_WISE_ONLINE_ADMISSION)
		// 			},

		// 		]
		// 	},
		// ] : [],

		... (instituteInfo?.roleList?.includes('ROLE_CRM')) ? [
			{



				label: 'Institute Billing',
				key: 'iBilling',
				icon: <PartitionOutlined />,
				children: [

					{
						label: 'Institute List',
						key: ROUTES.INSTITUTE_LIST_CORE,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.INSTITUTE_LIST_CORE)
					},

					{
						label: 'OFPS Institute List',
						key: ROUTES.OFPS_INSTITUTE_LIST,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.OFPS_INSTITUTE_LIST)
					},

					{
						label: 'Partner Infos',
						key: ROUTES.PARTNER_INFO,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.PARTNER_INFO)
					},

					{
						label: 'Partner Assign (Institute)',
						key: ROUTES.INSTITUTE_LIST_CORE + 'ww',
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.PARTNER_ASSIGN_INSTITUTE)
					},

					{
						label: 'Partner Mapping',
						key: ROUTES.PARTNER_MAPPING ,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.PARTNER_MAPPING)
					},


					{
						label: 'Bill',
						key: 'bill',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'Create',
								key: ROUTES.BILL_CREATE,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.BILL_CREATE)
							},
							{
								label: 'Manual Create',
								key: ROUTES.BILL_CREATE_MANUAL,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.BILL_CREATE_MANUAL)
							},
							{
								label: 'Update',
								key: ROUTES.BILL_UPDATE,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.BILL_UPDATE)
							}
						]
					},

				]
			},
		] : [],


		... (instituteInfo?.roleList?.includes('ROLE_PARTNER')) ? [
	
			{
			
			label: 'Partner',
			key: 'partnerInstitute',
			icon: <PartitionOutlined />,
			children: [

				{
					label: 'Institute List',
					key: ROUTES.PARTNER_SCHOOL_LIST,
					icon: <FileTextOutlined />,
					onClick: () => allPackage(ROUTES.PARTNER_SCHOOL_LIST)
				},
			]
	
		  }
		
	    ] : [],


		... (instituteInfo?.roleList?.includes('ROLE_MANAGEMENT')) ? [
			{

				label: 'Management',
				key: 'management',
				icon: <PartitionOutlined />,
				children: [

					{
						label: 'Institute List',
						key: ROUTES.INSTITUTE_LIST_MANAGEMENT,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.INSTITUTE_LIST_MANAGEMENT)
					},

					{
						label: 'Partner Infos',
						key: ROUTES.PARTNER_INFO,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.PARTNER_INFO)
					},

					{
						label: 'OFPS Institute List',
						key: ROUTES.OFPS_INSTITUTE_LIST_MANAGEMENT,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.OFPS_INSTITUTE_LIST_MANAGEMENT)
					},

					{
						label: 'Bill Info',
						key: ROUTES.INSTITUTE_BILL_LIST_MANAGEMENT,
						icon: <FileTextOutlined />,
						onClick: () => allPackage(ROUTES.INSTITUTE_BILL_LIST_MANAGEMENT)
					},

					{
						label: 'Collection',
						key: 'ofpsAndBillCollection',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'Bill',
								key: ROUTES.INSTITUTE_DATE_TO_DATE_BILL_REPORT_MANAGEMENT,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.INSTITUTE_DATE_TO_DATE_BILL_REPORT_MANAGEMENT)
							},
							{
								label: 'OFPS',
								key: ROUTES.INSTITUTE_DATE_TO_DATE_OFPS_REPORT_MANAGEMENT,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.INSTITUTE_DATE_TO_DATE_OFPS_REPORT_MANAGEMENT)
							},
							{
								label: 'Graphical View',
								key: ROUTES.GRAPHICAL_VIEW,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.GRAPHICAL_VIEW)
							},
						]
					},
					{
						label: 'Marketing Report',
						key: 'marketingReport',
						icon: <PartitionOutlined />,
						children: [
							{
								label: 'Institute Count',
								key: ROUTES.INSTITUTE_COUNT_STAT,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.INSTITUTE_COUNT_STAT)
							},

							{
								label: 'Person Institute',
								key: ROUTES.PERSON_WISE_INSTITUTE,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.PERSON_WISE_INSTITUTE)
							},

							{
								label: 'Person Bill',
								key: ROUTES.PERSON_BILL_COLLECTION,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.PERSON_BILL_COLLECTION)
							},

							{
								label: 'Institute Transaction Status',
								key: ROUTES.INSTITUTE_TRANSACTION_STATUS,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.INSTITUTE_TRANSACTION_STATUS)
							},
							{
								label: 'Institute Create List',
								key: ROUTES.INSTITUTE_CREATE_LIST,
								icon: <FileTextOutlined />,
								onClick: () => allPackage(ROUTES.INSTITUTE_CREATE_LIST)
							},
						]
					},

				]
			},
		] : [],

	];






	// console.log(menuItems);

	// console.log(menuItems)
	return <> {view &&
		<Menu
			theme="dark"
			defaultSelectedKeys={[pathValue]}
			mode="inline"
			defaultOpenKeys={refreshKeys}
			selectedKeys={[pathValue]}
			onClick={sideClick}
			onOpenChange={onOpenChange}
			openKeys={keys}
			items={menuItems}
		>


		</Menu>
	}
	</>

}
