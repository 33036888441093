export const ROUTES = {
  DEFAULT: "/",

  LEDGER_LIST: "ledger-list",
  RECEIVE_TRN_TRANSACTION: "receive-trn-transaction",
  PAYMENT_TRN_TRANSACTION: "payment-trn-transaction",
  CONTRA_TRN_TRANSACTION: "contra-trn-transaction",
  JOURNAL_TRN_TRANSACTION: "journal-trn-transaction",
  DELETE_VOUCHER_GAVOUCHER: "delete-voucher-gavoucher",
  TRIAL_BALANCE_GAREPORT: "trial-balance-gareport",
  BALANCE_SHEET_GAREPORT: "balance-sheet-gareport",
  INCOME_STATEMENT_GAREPORT: "income-statement-gareport",
  JOURNAL_GAREPORT: "journal-gareport",
  FUNDS_FLOW_GAREPORT: "funds-flow-gareport",
  CASH_SUMMARY_GAREPORT: "cash-summary-gareport",
  USER_WISE_TRAN: "user-wise-tran-gareport",
  VOUCHER_TEMPLATE: "voucher-template-gareport",
  ACCOUNT_BOOK: "account-book-gareport",
  STUDENT_WRAPPER: "student-setting",
  STUDENT_CLASS_CONFIG: "initial-setup-class-configuration",
  CLASS_SEMESTER_CONFIG: "initial-setup-group-configuration",
  GROUP_CONFIG_CUSTOMER_SUPPORT: "group-configuration-customer-support",
  STUDENT_MULTIPLE_REGISTRATION: "student-multiple-registration",
  STUDENT_MULTIPLE_REGISTRATION_EXCEL: "student-multiple-registration-excel",
  STUDENT_BASIC_UPDATE: "student-basic-update",
  STUDENT_BASIC_UPDATE_INTERNATIONAL: "student-basic-update-international",
  STUDENT_PROFILE_UPDATE: "student-profile-update",
  STUDENT_MIGRATION: "student-migration",
  STUDENT_PHOTO_UPDATE: "student-photo-update",
  STUDENT_ACADEMICINFO_UPDATE: "student-academic-info-update",
  STUDENT_ADDRESS_UPDATE: "student-address-update",
  STUDENT_REGISTRATION_DELETE: "student-registration-delete",
  STUDENT_ID_CARD_INFORMATION: "student-id-card-information",
  STUDENT_ID_CARD_TEMPLATE: "student-id-card-template",
  STUDENT_ID_CARD_TEMPLATE_DOWNLOAD: "student-id-card-download",
  CLASS_WISE_REPORT: "student-report-basic-info-list-class-wise",
  SECTION_WISE_STUDENT_REPORT: "student-report-basic-info-list",
  GROUP_WISE_STUDENT_REPORT: "student-report-basic-info-list-group-wise",
  TAUGHT_LIST_STUDENT_REPORT: "student-report-basic-info-list-taught-list",
  SECTION_WISE_SUMMARY_REPORT: "student-report-section-wise-summary",
  SECTION_WISE_SUMMARY_REPORT_INTERNATIONAL: "student-report-section-wise-summary-international",
  GENDER_WISE_SUMMARY_REPORT: "student-report-gender-wise-summary",
  RELIGION_WISE_SUMMARY_REPORT: "student-report-religion-wise-summary",
  CLASS_WISE_SUMMARY_REPORT: "student-report-class-wise-summary",
  SUBJECT_WISE_SUMMARY_REPORT: "student-report-subject-wise-summary",
  SECTION_OVERVIEW_REPORT: "student-report-overview",
  SECTION_PROFILE_REPORT: "student-profile",
  STUDENT_SINGLE_SEARCH: "student-single-search",
  STUDENT_UPDATE_ID: "student-id-update",
  STUDENT_ENABLED_DISABLED: "student-enable-disable",
  GENERAL_STUDENT_MIGRATION: "general-student-migration",
  EXCEL_STUDENT_MIGRATION: "excel-student-migration",
  MERIT_STUDENT_MIGRATION: "merit-student-migration",
  STUDENT_REPORT_BASIC_INFO_CATEGORY_WISE_REPORT: "student-report-basic-info-list-category-wise",

  /*******HR Attendance*/
  HR_ATTENDANCE_TAKE: "hr-attendance-take",
  HR_ATTENDANCE_UPDATE: "hr-attendance-update",
  HR_SINGLE_LEAVE: "hr-single-leave",
  HR_TIME_CONFIG: "hr-time-config",
  HR_TD_MAPPING: "hr-id-mapping",
  HR_FILE_UPLOAD: "hr-file-upload",
  HR_REPORT_TIME_CONFIG: "hr-report-time-config",
  HR_REPORT_DATE_WISE: "hr-report-date-wise",
  HR_REPORT_MONTH_WISE: "hr-report-month-wise",
  HR_REMARK_DATE_WISE: "hr-remark-date-wise",
  HOLIDAY: "holiday",
  STAFF_ATTENDANCE_MACHINE: "staff-attendance-machine",
  /*******HR Attendance*/

  STAFF_SETTINGS: "staff-settings",
  STAFF_SINGLE_REGISTRATION: "staff-single-registration",
  STAFF_MULTIPLE_REGISTRATION: "staff-multiple-registration",
  STAFF_UPDATE: "staff-update-update",
  STAFF_UPDATE_SINGLE: "staff-update-single",
  STAFF_ASSIGN: "staff-assign-teacher",
  STAFF_LIST: "staff-list",
  SUBJECT_TEACHER_ASSIGN: "subject-teacher-assign",
  STAFF_TEMPLATE: "staff-template",
  STAFF_ID_TEMP: "staff-id-template",
  ATTENDANCE_FINE: "attendance-fine",
  FEE_HEAD: "fee-head-settings",
  FEE_SUB_HEAD: "fee-sub-head-settings",
  FEE_HEAD_WAIVER: "fee-head-waiver-settings",
  FEE_HEAD_LEDGER_CONFIGUARTION: "fee-head-ledger-configuration",
  FEE_SUBHEAD_TIME_CONFIGUARTION: "fee-subhead-time-configuration",
  OFPS_ACCOUNT_CONFIG: "ofps-account-configuration",
  FEE_SUB_HEAD_LEDGER_CONFIGUARTION: "fee-sub-head-ledger-configuration",
  FEE_FINE_LEDGER_CONFIGUARTION: "fee-fine-ledger-configuration",
  FEE_AMOUNT_CONFIGUARTION: "fee-amount-configuration",
  FEE_WAIVE_CONFIGUARTION: "fee-waive-configuration",
  FEE_HEAD_DELETE_CONFIGUARTION: "fee-head-delete-configuration",
  FEE_COLLECTION_MANUAL: "fee-collection-manual",
  FEE_REPORT_SECTION_PAID_LIST: "fee-report-section-paid-list",
  FEE_REPORT_CLASS_PAID_LIST: "fee-report-class-paid-list",
  FEE_REPORT_ALL_PAID_LIST: "fee-report-all-paid-list",
  FEE_REPORT_SECTION_DUE: "fee-report-section-due",
  FEE_REPORT_MONTHLY_DUE: "fee-report-monthly-due",
  FEE_REPORT_FEE_COLLECTION: "fee-report-feehead-collection",
  FEE_REPORT_FEE_COLLECTION_CLASS: "fee-report-feehead-collection-class",
  FEE_HEAD_COLLECTION_DATE_TO_DATE_ALL_SECTION: "fee-head-collection-date-to-date-all-section",
  STUDENT_GIVEN_WAIVER: "student-given-waiver",
  FEE_SUBHEAD_COLLECTION_REPORT: "fee-subhead-collection-report",
  FEE_DELETE_INVOICE: "fee-invoice-delete",
  FEE_DISCARD: "fee-discard",
  FEE_AMOUNT_VIEW: "fee-amount-view",
  FEE_SUBHEAD_DISCARD: "fee-subhead-discard",
  SPG_STATEMENT: "spg-statement",
  SPG_SUMMARY: "spg-summary",
  BKASH_STATEMENT: "bkash-statement",
  BKASH_SUMMARY: "bkash-summary",
  YEARLY_FEE_DETAILS: "yearly-fee-details",
  MONEY_RECIPT_TEMPLATE: "fee-money-receipt-template",
  INDIVIDUAL_FEE_ASSIGN: "individual-fee-assign",
  EXAM_ROUTINE2: "exam-routine-new",
  EXAM_BLANK_SHEET: "exam-blank-sheet",
  UPAY_PGW_STATEMENT: "upay-pgw-statement",
  UPAY_PGW_SUMMARY: "upay-pgw-summary",
  UPAY_PAY_BILL: "upay-pay-bill",
  PREMIER_BANK_SSL_STATEMENT: "premier-bank-ssl-statement",

  USER_LIST: "user-list",
  INITIAL_SETUP_PERIOD: "initial-setup-period",
  STUDENT_ATTENDANCE_PERIOD_TIME_CONFIGURATION: "student-attendance-period-time-configuration",
  STUDENT_DEVICE_ID_MAPPING: "student-device-id-mapping",
  STUDENT_ATTENDANCE_INPUT: "student-attendance-input",
  STUDENT_ATTENDANCE_UPDATE: "student-attendance-update",
  STUDENT_ATTENDANCE_EXAM: "student-attendance-exam",
  STUDENT_LEAVE_INPUT: "student-leave-input",
  DAILY_ATTENSION_REPORT_DAILY_SECTION: "student-attendance-report-daily-section",
  DAILY_ATTENSION_REPORT_SECTION_WISE_DAILY_SUMMARY: "student-attendance-report-section-wise-daily-summary",
  STUDENT_ATTENDANCE_REPORT_MONTHLY_SECTION_DETAILS: "student-attendance-report-monthly-section-details",
  STUDENT_ATTENDANCE_REPORT_MONTHLY_SECTION_SUMMARY: "student-attendance-report-monthly-section-summary",
  STUDENT_ATTENDANCE_MACHINE_INPUT: "student-attendance-machine-input",
  STUDENT_ATTENDANCE_DEVICE: "student-attendance-device",
  /*******Exam*********/
  EXAM_SETTING: "exam-setting",
  EXAM_SUBJECT_CONFIGURATION: "exam-subject-configuration",
  EXAM_CONFIGURATION: "exam-configuration",
  EXAM_MARK_CONFIGURE: "exam-mark-configuration",
  EXAM_GRANDFINAL_MARK_CONFIGURE: "exam-grandfinal-mark-configuration",
  EXAM_FOUR_SUBJECT_ASSIGN: "exam-forth-subject-assign",
  ADMIT_CARD_INSTRUCTION: "admit-card-instruction",
  EXAM_TEMPLATE: "exam-marksheet-template",
  ADMIT_CARD_SEAT_PLAN: "admit-card-seat-plan-download",
  ADMIT_CARD_WITH_ROUTINE: "admit-card-with-routine-download",
  EXAM_MARK_INPUT: "exam-mark-input",
  EXAM_MARK_UPDATE: "exam-mark-update",
  EXAM_MARK_INPUT_SPECIAL: "exam-mark-input-special",
  EXAM_MARK_UPDATE_SPECIAL: "exam-mark-update-special",
  EXAM_MARK_SHEET_SPECIAL: "exam-mark-sheet-special",
  EXAM_MARK_INPUT_SUBJECT: "exam-mark-input-subject",
  EXAM_MARK_UPDATE_SUBJECT: "exam-mark-update-subject",
  EXAM_MARK_PROBLEM: "exam-mark-problem",
  EXAM_MARK_PROCESS_CLASS: "exam-mark-process-class",
  EXAM_MARK_DELETE: "exam-mark-delete",
  EXAM_RESULT_PUBLISH: "exam-result-publish",
  EXAM_GENERAL_RESULT_PROCESS: "exam-result-process-general",
  EXAM_GENERAL_RESULT_PROCESS_GRAND_FINAL: "exam-result-process-grand-final",
  EXAM_GENERAL_RESULT_MERIT_POSITION: "exam-merit-position-generate",
  EXAM_REMARKS_GIVE: "exam-remarks-give",
  EXAM_GENERAL_TEBULATION_SHEET: "exam-general-tabulation-sheet-download",
  EXAM_GENERAL_MARKSHEET_DOWNLOAD: "exam-general-marksheet-download",
  EXAM_GRAND_MARKSHEET_DOWNLOAD: "exam-grand-final-marksheet-download",
  EXAM_REPORT_SECTION_WISE_UNINPUTTED_SUBJECTS: "exam-report-section-wise-un-inputted-subjects",
  EXAM_REPORT_SECTION_RESULT: "exam-report-section-result",
  EXAM_REPORT_CLASS_RESULT: "exam-report-class-result",
  EXAM_REPORT_SECTION_WISE_MERIT_LIST: "exam-report-section-merit-position",
  EXAM_REPORT_CLASS_WISE_MERIT_LIST: "exam-report-class-merit-position",
  EXAM_REPORT_GROUP_WISE_MERIT_LIST: "exam-report-group-merit-position",
  EXAM_REPORT_CLASS_FAILED: "exam-report-class-failed-list",
  EXAM_REPORT_CLASS_MERIT: "exam-report-class-merit-list",
  EXAM_REPORT_SECTION_FAILED: "exam-report-section-failed-list",
  EXAM_REPORT_SECTION_WISE_GRADING_SUMMARY: "exam-report-section-wise-grading-summary",
  EXAM_REPORT_SUBJECT_WISE_GRADING_SUMMARY: "exam-report-subject-wise-grading-summary",
  EXAM_REPORT_SECTION_WISE_PASS_FAIL_SUMMARY: "exam-report-section-wise-pass-fail-summary",
  ADMIT_CARD_DOWNLOAD_CONDITION: "admit-card-download-condition",
  /****************/

  /*****Inventory******/
  INVENTORY_CATEGORY: "inventory-category",
  INVENTORY_ITEM: "inventory-item",
  INVENTORY_SALER: "inventory-saler",
  INVENTORY_LEDGER_CONFIGURATION: "inventory-ledger-configuration",
  INVENTORY_PURCHASE: "inventory-purchase",
  INVENTORY_PURCHASE_SAVE: "inventory-purchase-save",
  INVENTORY_PURCHASE_VIEW: "inventory-purchase-view",  
  INVENTORY_SALES_SAVE: "inventory-sales-save",
  INVENTORY_SALES_VIEW: "inventory-sales-view",
  INVENTORY_ITEM_RECORD_IN: "inventory-item-record-in",
  INVENTORY_ITEM_RECORD_OUT: "inventory-item-record-out",
  INVENTORY_ITEM_DESTROY_RECORD: "inventory-item-destroy-record",
  
  /*****Payslip******/
  PAYSLIP_CREATE_SINGLE: "payslip-create-single",
  PAYSLIP_CREATE_BATCH: "payslip-create-batch",
  PAYSLIP_COLLECT: "payslip-collect",
  PAYSLIP_TEMPLATE: "payslip-template",
  PAYSLIP_DATE_UPDATE: "payslip-date-update",
  /**************/

  /*****SMS******/
  SMS_SEND_DYNAMIC: "sms-send-dynamic",
  STAFF_SMS: "sms-send-to-staff",
  STUDENT_CLASSWISE_SMS: "send-sms-to-student-classwise",
  STUDENT_SECTIONWISE_SMS: "send-sms-to-student-sectionswise",
  STUDENT_INSTIUTE_SMS: "send-sms-to-student-institutewise",
  SECTION_DUE_SMS: "send-sms-to-student-duewise",
  SMS_TEMPLATE: "sms-template",
  GLOBAL_SMS_TEMPLATE: "global-sms-template",
  SMS_PURCHASE: "purchase-sms",
  SMS_PURCHASE_LOG: "sms-purchase-log",
  GENERAL_SMS_LIST: "sms-report-general-view",
  STAFF_SMS_LIST: "sms-report-staff-view",
  STUDENT_SMS_LIST: "sms-report-student-view",
  SMS_BALANCE: "sms-balance",
  FEE_PAID_SMS: "fee-paid-sms",
  SEND_SMS_EXAM_RESULT: "send-sms-to-student-examresult",
  REGISTRATION_SMS: "send-registration-sms",
  /**************/

  /*******Master Setting*/
  INSTITUTE_UPDATE: "institute-update",
  DEFAULT_SIGNATURE: "default-signature",
  MASTER_USER_LIST: "users-list",
  MASTER_PAYABLE_BILL_LIST: "payable-bill",
  MASTER_PAID_BILL_LIST: "paid-bill",
  /********/

  /*******TC/Testimonial*/
  TESTIMONIALS: "testimonial",
  TRANSFER_CERTIFICATE: "transfer",
  /********/


  /*******Library Management*/
  BOOKCATEGORY: "book-category",
  ADDWRITER: "add-writer",
  ADDCOWRITER: "add-co-writer",
  /********/

  /*******Customer Support*/
  INSTITUTE_LIST: "institute-list",
  MIGRATION_CHANGE: "migration-change",
  SHEBA_SCHOOL_INFO: "sheba-school-info",
  ZKTECO_DEVICE_SETTINGS: "zkteco-device-settings",
  INSTITUTE_LIST_CORE: "institute-list-core",
  INSTITUTE_LIST_MANAGEMENT: "institute-list-management",
  BKASH_OFPS_INSTITUTE_LIST: "bkash-ofps-institute-list",
  OFPS_INSTITUTE_LIST: "ofps_institute-list",
  OFPS_INSTITUTE_LIST_MANAGEMENT: "ofps-institute-list-management",
  INSTITUTE_STUDENT_COUNT: "institute-student-count",
  INSTITUTE_JUMP: "institute-jump",
  EXAM_GRADE_UPDATE: "exam-grade-update",
  OFPS_PROBLEM: "ofps-problem",
  BKASH_OFPS_PROBLEM: "bkash-ofps-problem",
  UPAY_PGW_OFPS_PROBLEM: "upay-pgw-ofps-problem",
  BKASH_REFUND_VIEW: "bkash-refund-view",
  PARTNER_INFORMATION: "partner-information",
  BKASH_OFPS_CONFIGURATION_SAVE: "bkash-ofps-configuration-save",

  /*******Customer Support Sanmasik*/
  SANMASIK_CLASS: "sanmasik-class",
  SANMASIK_SUBJECT: "sanmasik-subject",
  SANMASIK_COMPETENCY: "sanmasik-competency",
  SANMASIK_PI: "sanmasik-pi",
  SANMASIK_CHAPTER: "sanmasik-chapter",
  SANMASIK_PI_INPUT: "sanmasik-pi-input",
  SANMASIK_TRANSCRIPT_DOWNLOAD: "sanmasik-transcript-download",

  SANMASIK_CLASS_INSTITUTE_CLASS_CONFIGURATION: "sanmasik-class-institute-class-configuration",

  /*******Miscellaneous*/
  CLASS_ROUTINE: "class-routine",
  EXAM_ROUTINE: "exam-routine",
  /***********online admission*/
  SECTION_WISE_ONLINE_ADMISSION: "section-wise-online-admission",
  GROUP_WISE_ONLINE_ADMISSION: "group-wise-online-admission",
  PAYMENT_STATUS_WISE_ONLINE_ADMISSION: "payment-status-wise-online-admission",

  ONLINE_ADMISSION_REPORT_PAID_UNPAID: "online-admission-report-paid-unpaid",
  ONLINE_ADMISSION_GENERAL_REPORT: "online-admission-general-report",
  ONLINE_ADMISSION_SPG_PROBLEM_VIEW: "online-admission-spg-problem-view",
  ONLINE_ADMISSION_STUDENT_REGISTRATION: "online-admission-student-registration",
  ONLINE_ADMISSION_INFO_EDIT: "online-admission-info-edit",

  ALUMNI_STUDENT_VIEW: "alumni-student-view",
  ALUMNI_SPG_PROBLEM_VIEW: "alumni-spg-problem-view",

  /***********Institute Billing***************/
  BILL_CREATE: "bill-create",
  BILL_UPDATE: "bill-update",
  BILL_CREATE_MANUAL: "bill-create-manual",
  INSTITUTE_BILL_LIST_MANAGEMENT: "institute-bill-list-management",
  INSTITUTE_DATE_TO_DATE_BILL_REPORT_MANAGEMENT: "institute-date-to-date-bill-report-management",
  INSTITUTE_DATE_TO_DATE_OFPS_REPORT_MANAGEMENT: "institute-date-to-date-ofps-report-management",

  SINGLE_INSTITUTE_PAYABLE_BILL: "single-institute-payable-bill",
  SINGLE_INSTITUTE_PAID_BILL: "single-institute-paid-bill",

  /***********Partner Info***************/
  PARTNER_INFO: "partner-info",
  PARTNER_INFO_SAVE: "partner-info-save",
  PARTNER_ASSIGN_INSTITUTE: "partner-assign-institute",
  PARTNER_MAPPING: "partner-mapping",
  PARTNER_SCHOOL_LIST: "partner-school-list",
  GRAPHICAL_VIEW: "graphical-view",
  SEAT_PLAN: "seat-plan",
  
  PERSON_WISE_INSTITUTE: "person-wise-institute",
  PERSON_BILL_COLLECTION: "person-bill-collection",
  INSTITUTE_COUNT_STAT: "institute-count-stat",
  INSTITUTE_TRANSACTION_STATUS: "institute-transaction-status",
  INSTITUTE_CREATE_LIST: "institute-create-list",


  /***********Payroll***************/
  SETTINGS_PAYROLL_ADDITION: "settings-payroll-addition",
  SETTINGS_PAYROLL_DEDUCTION: "settings-payroll-deduction",
  SETTINGS_PAYROLL_GRADE: "settings-payroll-grade",
  EMPLOYEE_SALARY_CONFIGUARION:"employee-salary-configuration",
  EMPLOYEE_BANK_ACCOUNT:"employee-bank-account",
  SALARY_DEFAULT_CONFIG:"salary-default-config",
  SALARY_SHEET_CREATE:"salary-sheet-create",
  SALARY_PAYMENT:"salary-payment",
  BANK_ADVISE_CONTENT:"bank-advise-content",


  ERROR_PAGE: "error-page",
};
