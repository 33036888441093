import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { login, logout, postpasswordChange, fetchsendPasswordRecoveryToken, fetchresetPassword, instituteInfo, fetchsingleInstituteBillUnpaidList } from '../../../http/auth/auth';
import { goToPartnerInstitute, jumpInstiuteUrl } from '../../../http/customerSupport/customerSupport';
import { updateInstituteInfoUrl, updatePhoto } from '../../../http/masterSettings/masterSettings';

export interface Auth {
	user: any | undefined,
	checkAuth: Thunk<Auth, any>;
	authenticated: Action<Auth, any>;
	authenticate: Thunk<Auth, any>;
	logout: Action<Auth, any>;
	loginFailed: Action<Auth, string>;
	error?: string;
	busy?: boolean;
	setBusy: Action<Auth, boolean>;
	logoutclear: Thunk<Auth, any>;
	postpasswordChange: Thunk<Auth, any>;


	sendPasswordRecoveryToken: boolean,
	setsendPasswordRecoveryToken: Action<Auth, any>;
	fetchsendPasswordRecoveryToken: Thunk<Auth, any>;

	resetPassword: boolean,
	setresetPassword: Action<Auth, any>;
	fetchresetPassword: Thunk<Auth, any>;
	fetchJumpInstiute: Thunk<Auth, any>;
	instituteInfo: any,
	setinstituteInfo: Action<Auth, any>;
	fetchInstituteInfo: Thunk<Auth, any>;
	goToPartnerInstitute: Thunk<Auth, any>;
	updateinstituteInfo: Thunk<Auth, any>;
	updateinstitutePhoto: Thunk<Auth,any>;

	singleInstituteBillUnpaidList: any,
	setsingleInstituteBillUnpaidList: Action<Auth, any>;
	fetchsingleInstituteBillUnpaidList: Thunk<Auth, any>;
}

export let token: string | undefined = undefined;

export const authStore: Auth = {
	user: undefined,
	checkAuth: thunk(async (actions) => {
		const jwt: any = localStorage.getItem("jwt");
		const insinfo: any = localStorage.getItem("insinfo");
		if (jwt) {
			try {
				actions.authenticated(JSON.parse(jwt));
				actions.setinstituteInfo(JSON.parse(insinfo));
			} catch (e) {
				console.error("[Auth] Failed");
			}
		}
	}),
	authenticate: thunk(async (actions, payload) => {
		//console.log('here')
		const response = await login(payload);
		console.log(response);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			let url: any = process.env.REACT_APP_API_ROOT
			localStorage.setItem("url", url);
			localStorage.setItem("tok", body.access_token);
			if (payload.remember) {
				localStorage.setItem("jwt", JSON.stringify(body));

			} else {
				localStorage.removeItem("jwt");
				localStorage.removeItem("openKeys");
			}

			actions.authenticated(body);


			///
			let headerx = {};
			headerx['Authorization'] = `Bearer ${body.access_token}`;
			let insinfo = await fetch(`${process.env.REACT_APP_API_ROOT}/institute/view`, {
				method: "GET",
				mode: 'cors',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/json', ...headerx
				},
			});
			if (insinfo.status === 200) {
				const bodyx = await insinfo.json();
				actions.setinstituteInfo(bodyx.item);
				localStorage.setItem("insinfo", JSON.stringify(bodyx.item));
			} else {
				throw new Error("[Auth] Failed");
			}
		}  
		// if(response.status === 400) {
		// 	actions.setBusy(false)
		// 	const res = await fetch(`${process.env.REACT_APP_API_ROOT}/public/disable/institute?userName=${payload.username}&password=${payload.password}`, {
		// 		method: "GET",
		// 		mode: 'cors',
		// 		cache: 'no-cache',
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 		},
		// 	});
		// 	// console.log(res)
		// 	if (res.status === 201) {
		// 		const bodyx = await res.json();
		// 		if (bodyx?.statusCode===3){
		// 			actions.loginFailed("Invalid Username/Password");
		// 		} 
		// 		if (bodyx?.statusCode===4){
		// 			localStorage.setItem("disabled", JSON.stringify(bodyx));
		// 			window.location.href = '/disabled';
		// 		} 
		// 	} 	if (res.status === 400) {
		// 		actions.loginFailed("Invalid Username/Password");
	
		// 	}
		// 	 else {
		// 		actions.loginFailed("Invalid Username/Password");
		// 	}
		// }
		else {
			//const body = await response.json();
			actions.loginFailed("Invalid Username/Password");
		}
	}),
	authenticated: action((state, auth) => {
		token = auth?.access_token
		state.user = auth;
	}),
	setBusy: action((state, isbusy) => {
		state.busy = isbusy;
	}),
	loginFailed: action((state, message) => {
		state.busy = false;
		state.error = message;
	}),
	logout: action((state, payload) => {
		console.log(payload)
		//const response = logout(payload);
		// localStorage.removeItem("jwt");
		// localStorage.removeItem("url");
		// localStorage.removeItem("tok");
		// localStorage.removeItem("openKeys");
		localStorage.clear();
		state.busy = false;
		state.error = "";
		window.location.reload()
	}),
	logoutclear: thunk(async (actions, payload) => {
		const response = await logout(payload);
		if (response.status === 201 || response.status === 200) {
			console.log("Clear")
		} else console.log("Error")
	}),
	postpasswordChange: thunk(async (actions, payload) => {
		const response = await postpasswordChange(payload);
		if (response.status === 200) {
			const body: any = await response.json();
			if (body?.messageType === 1) {
				notification['success']({
					message: 'Password',
					description: body?.message,
				})
			};
			if (body?.messageType === 0) {
				notification['error']({
					message: 'Password',
					description: body?.message,
				})
			};
		} else {

			notification['error']({
				message: 'Password',
				description: 'Something went wrong ',
			});
		}
	}),

	sendPasswordRecoveryToken: false,
	fetchsendPasswordRecoveryToken: thunk(async (actions, payload) => {
		// console.log(payload)
		const response = await fetchsendPasswordRecoveryToken(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				actions.setsendPasswordRecoveryToken(true);
			} else {
				actions.setsendPasswordRecoveryToken(false);
				notification['error']({
					message: 'Password',
					description: body?.message,
				});
			}


		} else {
			actions.setsendPasswordRecoveryToken(false);
		}
	}),
	setsendPasswordRecoveryToken: action((state, payload) => {
		state.sendPasswordRecoveryToken = payload;
	}),

	resetPassword: false,
	fetchresetPassword: thunk(async (actions, payload) => {
		// console.log(payload)
		const response = await fetchresetPassword(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				actions.setresetPassword(true);
			} else {
				actions.setresetPassword(false);
				notification['error']({
					message: 'Password',
					description: body?.message,
				});
			}


		} else {
			actions.setresetPassword(false);
		}
	}),
	setresetPassword: action((state, payload) => {
		state.resetPassword = payload;
	}),
	instituteInfo: {},
	setinstituteInfo: action((state, payload) => {
		state.instituteInfo = payload;
	}),
	fetchInstituteInfo:thunk(async (actions, payload, { getState }) => {

		//console.log(getState());
		const response2 = await instituteInfo();
		let tok = getState()?.user?.access_token
		if (response2.status === 401) {
			notification['error']({
				message: 'Session',
				description: "Session has expired",
			});
			actions.logoutclear(tok);
			
			setTimeout(() => {
				localStorage.clear();
				window.location.reload()
			}, 500);
		}
		else {

		}
	}),
	
	fetchJumpInstiute: thunk(async (actions, payload) => {
		// console.log(payload)
		const response = await jumpInstiuteUrl(payload);
		if (response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				const response2 = await instituteInfo();
				if (response2.status === 201 || response2.status === 200) {
					const body2 = await response2.json();
					actions.setinstituteInfo(body2.item);
					localStorage.setItem("insinfo", JSON.stringify(body2.item));
					localStorage.setItem('shebaSchool', JSON.stringify([1]));
					localStorage.setItem('shebaSchoolKey', ('1'));
					window.location.href = '/';
				}
			} else {

				notification['error']({
					message: 'Info',
					description: body?.message,
				});
			}


		} else {

		}
	}),



	updateinstituteInfo: thunk(async (actions, payload) => {
		// console.log(payload)
		const response = await updateInstituteInfoUrl(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification['success']({ message: 'Institute', description: body?.message });
				const response2 = await instituteInfo();
				if (response2.status === 201 || response2.status === 200) {
					const body2 = await response2.json();
					actions.setinstituteInfo(body2.item);
					localStorage.setItem("insinfo", JSON.stringify(body2.item));
				}
			} else {

				notification['error']({
					message: 'Info',
					description: body?.message,
				});
			}


		} else {

		}
	}),

	updateinstitutePhoto:thunk(async (actions, payload) => {
        var data = new FormData()
        data.append('file', payload)
        const response = await updatePhoto(data);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body.messageType == 1){
				notification['success']({ message: 'Institute', description: body?.message });
				const response2 = await instituteInfo();
				if (response2.status === 201 || response2.status === 200) {
					const body2 = await response2.json();
					actions.setinstituteInfo(body2.item);
					localStorage.setItem("insinfo", JSON.stringify(body2.item));
				}
            }else{
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),


	goToPartnerInstitute: thunk(async (actions, payload) => {
        const response = await goToPartnerInstitute(payload);
        if (response.status === 200) {
            const body = await response.json();
            if (body?.messageType === 1) {
                const response2 = await instituteInfo();
                if (response2.status === 201 || response2.status === 200) {
                    const body2 = await response2.json();
                    actions.setinstituteInfo(body2.item);
                    localStorage.setItem("insinfo", JSON.stringify(body2.item));
                    localStorage.setItem('shebaSchool', JSON.stringify([1]));
                    localStorage.setItem('shebaSchoolKey', ('1'));
                    window.location.href = '/';
                }
            } else {

                notification['error']({
                    message: 'Info',
                    description: body?.message,
                });
            }


        } else {

        }
    }),

	singleInstituteBillUnpaidList: [],
	setsingleInstituteBillUnpaidList: action((state, payload) => {
		state.singleInstituteBillUnpaidList = payload;
	}),
	fetchsingleInstituteBillUnpaidList: thunk(async (actions, payload) => {
		const response = await fetchsingleInstituteBillUnpaidList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType== 1) {
				actions.setsingleInstituteBillUnpaidList(body.item);
			} else {
				notification['error']({
					message: 'No Data Found',
				});
				actions.setsingleInstituteBillUnpaidList([]);
			}
		} else {
			notification['error']({
				message: 'Something went wrong',
			});
			actions.setsingleInstituteBillUnpaidList([]);
		}
	}), 
	
}
