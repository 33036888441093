import React, { useCallback, useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectGroupbySection } from '../../../select/SelectGroupbySection';
import { DownCircleOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useTable, usePagination, useFilters } from "react-table";
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';

const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id, Header },
    updateMyData, // This is a custom function that we supplied to our table instance
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);



    const onChange = (e) => {
        updateMyData(index, id, e.target.value);
    }
    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return <select className="ant-input" style={{ border: "1px solid #03D665" }} value={value} onChange={onChange}>
        <option value="" disabled>Select</option>
        <option value="Good">Good</option>
        <option value="Very Good">Very Good</option>
        <option value="Excellent">Excellent</option>
        <option value="Absent">Absent</option>
    </select>
};

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            className="ant-input"
            style={{ border: "1px solid #03D665" }}
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
        />
    )
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Filter: DefaultColumnFilter,
    Cell: EditableCell
};

// Be sure to pass our updateMyData and the skipPageReset option
function Table({ columns, data, updateMyData, skipPageReset }) {
    // For this example, we're using pagination to illustrate how to stop
    // the current page from resetting when our data changes
    // Otherwise, nothing is different here.
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 50 },
            autoResetFilters: false,
            defaultColumn,
            // use the skipPageReset option to disable page resetting temporarily
            autoResetPage: !skipPageReset,
            // updateMyData isn't part of the API, but
            // anything we put into these options will
            // automatically be available on the instance.
            // That way we can call this function from our
            // cell renderer!
            updateMyData
        },
        useFilters, // useFilters!
        usePagination
    );

    // Render the UI for your table
    return (
        <>
            <div className='ant-table-content' style={{ width: "100%" }}>
                <table {...getTableProps()} className='shebaTable' style={{ width: "100%" }}>
                    <thead className='ant-table-thead'>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th className='ant-table-cell' {...column.getHeaderProps()}>{column.render("Header")}
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>

                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody className='ant-table-tbody' {...getTableBodyProps()}>
                        {page.length > 0 && page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr className={`ant-table-row ant-table-row-level-${i}`} {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td className='ant-table-cell' {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <br />
                <div className="pagination" style={{ float: 'right' }}>
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} style={{ height: 40 }}>
                        {"<<"}
                    </button>{" "}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage} style={{ height: 40 }}>
                        {"<"}
                    </button>{" "}
                    <button onClick={() => nextPage()} disabled={!canNextPage} style={{ height: 40 }}>
                        {">"}
                    </button>{" "}
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} style={{ height: 40 }}>
                        {">>"}
                    </button>{" "}
                    <span>
                        Page{" "}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{" "}
                    </span>
                    <span>
                        | Go to page:{" "}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(page);
                            }}
                            className="ant-input"
                            style={{ width: "100px", height: 40, border: "1px solid #03D665" }}
                        />
                    </span>{" "}
                    <select
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                        style={{ height: 40, border: "1px solid #03D665" }}
                    >
                        {[10, 20, 30, 40, 50, 100, 200, 500, 1000].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    );
}



export default function MarkInputSpecial() {

    const { Option } = Select;
    const [markInputForm] = Form.useForm();
    const fetchSemesterListByClassConfigId = useStoreActions((state) => state.exam.fetchSemesterListByClassConfigId);
    const semesterListByClassConfigIdArray = useStoreState((state) => state.exam.semesterListByClassConfigIdArray);
    const fetchlistGroupbyClassConfig = useStoreActions((state) => state.student.fetchlistGroupbyClassConfig);
    const fetchSubjectByGroupConfigId = useStoreActions((state) => state.exam.fetchSubjectByGroupConfigId);
    const subjectByGroupConfigIdArray = useStoreState((state) => state.exam.subjectByGroupConfigIdArray);
    const fetchgetStudentListForMarkInputSpecial = useStoreActions((state) => state.exam.fetchgetStudentListForMarkInputSpecial);
    const getStudentListForMarkInputSpecial = useStoreState<any>((state) => state.exam.getStudentListForMarkInputSpecial);
    const saveStudentMarkSpecial = useStoreActions((state) => state.exam.saveStudentMarkSpecial);
    const [classConfigId, setClassConfigId] = useState<any>("");
    const [examId, setexamId] = useState<any>("");
    const [subjectId, setsubjectId] = useState<any>("");

    const [markInputTableData, setMarkInputTableData] = useState<any>([]);

    const [singleGroupConfigId, setSingleGroupConfigId] = useState<any>();


    const [markSaveObject, setMarkSaveObject] = useState<any>();
    const loading = useStoreState((state) => state.exam.loading);
    const loading1 = useStoreState((state) => state.exam.loading);

    useEffect(() => {
        setMarkInputTableData(getStudentListForMarkInputSpecial);
    }, [getStudentListForMarkInputSpecial]);

    const onChangeClassList = (e) => {
        fetchSemesterListByClassConfigId(e);
        fetchlistGroupbyClassConfig(e);
        setClassConfigId(e)
        setMarkInputTableData([]);

    }

    const searchSubjectByGroupId = (e) => {

        let payload: any = {
            classConfigId: classConfigId,
            groupId: e.groupId
        }
        setSingleGroupConfigId(e.groupId);
        fetchSubjectByGroupConfigId(payload);
    }


    const getMarkConfigListBySubject = (e) => {
        let payload: any = {
            examConfigId: examId,
            subjectId: e
        }
    }



    const searchMarkInputSearch = (value) => {
        let payload: any = {
            classConfigId: value.sectionList,
            examId: value.examId,
            groupId: value?.classGroup?.groupId,
            subjectId: value.selectSubject,
            academicYear: value.academicYear
        }

        fetchgetStudentListForMarkInputSpecial(payload);

        let saveObject = {
            classConfigId: value.sectionList,
            examId: value.examId,
            groupId: value.classGroup.groupId,
            subjectId: value.selectSubject,
            type: "insert",
            academicYear: value.academicYear,
        }

        setMarkSaveObject(saveObject);
        setMarkInputTableData([]);
    }

    const saveMarkInputValue = (e) => {
        let studentList = markInputTableData?.filter(item => item?.performance !== "")?.map(newitem => {
            return {
                "identificationId": newitem?.identificationId,
                "markInputId": newitem?.identificationId,
                "performance": newitem?.performance
            }
        })

        let payload = {
            "examId": markSaveObject.examId,
            "studentList": studentList,
            "subjectId": markSaveObject.subjectId
        }
        saveStudentMarkSpecial(payload);
        setMarkInputTableData([]);
    }

    const columns = React.useMemo(
        () => [
            {
                Header: "Delete",
                id: "delete",
                disableFilters: true,
                accessor: (str) => "delete",
                Cell: (tableProps) => (
                    <span
                        style={{
                            cursor: "pointer",
                            color: "blue",
                            textDecoration: "underline"
                        }}
                        onClick={() => {
                            // ES6 Syntax use the rvalue if your data is an array.
                            const dataCopy = [...markInputTableData];
                            // It should not matter what you name tableProps. It made the most sense to me.
                            dataCopy.splice(tableProps.row.index, 1);
                            setMarkInputTableData(dataCopy);
                        }}
                    >
                        Delete
                    </span>
                )
            },
            {
                Header: "Student Name",
                accessor: "studentName",
                Cell: function Cell(cell) {
                    return <span>{cell.value}</span>;
                }
            },
            {
                Header: "Student Id",
                accessor: "customStudentId",
                Cell: function Cell(cell) {
                    return <span>{cell.value}</span>;
                }
            },
            {
                Header: "Roll",
                accessor: "studentRoll",
                Cell: function Cell(cell) {
                    return <span>{cell.value}</span>;
                }
            },
            {
                Header: "Performance",
                accessor: "performance"
            },
        ],
        [markInputTableData]
    );


    const [skipPageReset, setSkipPageReset] = React.useState(false);

    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);

        setMarkInputTableData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value
                    };
                }
                return row;
            })
        );
    };

    // After data chagnes, we turn the flag back off
    // so that if data actually changes when we're not
    // editing it, the page is reset
    // React.useEffect(() => {
    //     setSkipPageReset(false);
    // }, [markInputTableData]);





    const [groupId, setGroupId] = useState<any>(null);

    return (
        <Card title="Mark Input">
            <>
                <Form
                    layout="vertical"
                    id="sessionInfo"
                    onFinish={searchMarkInputSearch}
                    form={markInputForm}
                >
                    <Row>


                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item
                                name="academicYear"
                                label="Academic Year"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please Select Academic Year" },
                                ]}
                            >
                                <SelectAcademicYear />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item
                                name="sectionList"
                                label="Section List"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select section" },
                                ]}
                            >
                                <SelectClassConfig onChange={(e) => { markInputForm.setFieldsValue({ examId: null, selectSubject: null }); setGroupId(null); onChangeClassList(e); setMarkInputTableData([]) }} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item
                                name="examId"
                                label="Exam List"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select semester" },
                                ]}
                            >
                                <Select placeholder="Exam list" style={{ width: "100%" }} onChange={(e) => {setexamId(e); setMarkInputTableData([])}}>
                                    {semesterListByClassConfigIdArray ? (
                                        semesterListByClassConfigIdArray.map((type, idx) => (
                                            <Option key={type.examId} value={type.examId}>
                                                {type.examName}
                                            </Option>
                                        ))
                                    ) : (
                                        <Option value="fetching">Fetching semester</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item
                                name="classGroup"
                                className="title-Text"
                                label="Group"
                                rules={[
                                    { required: true, message: "Select group list" },
                                ]}
                            >
                                <SelectGroupbySection selected={groupId} onChange={(e) => { setGroupId(e?.groupId); searchSubjectByGroupId(e); setMarkInputTableData([]) }} />

                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item
                                name="selectSubject"
                                label="Subject List"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select subject list" },
                                ]}
                            >
                                <Select placeholder="Semester list" style={{ width: "100%" }} onChange={(e) => { getMarkConfigListBySubject(e); setsubjectId(e); setMarkInputTableData([]) }}>
                                    {subjectByGroupConfigIdArray ? (
                                        subjectByGroupConfigIdArray.map((type, idx) => (
                                            <Option key={type.subjectId} value={type.subjectId}>
                                                {type.subjectName}
                                            </Option>
                                        ))
                                    ) : (
                                        <Option value="fetching">Fetching Subject</Option>
                                    )}
                                </Select>
                            </Form.Item>
                            {/* <div className="pull-right">
                                <Button type="primary" className="mt-0" style={{ marginRight: "15px" }} onClick={() => downloadPdfBlankSheet()} icon={<DownCircleOutlined />} >
                                    Download Blank Sheet
                                </Button>
                                <Button type="primary" id='markInputSubmit' className=" mt-0" htmlType="submit" icon={<SearchOutlined />} >
                                    Search
                                </Button>
                            </div> */}
                        </Col>

                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}></Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>

                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                            <Button type="primary" style={{ width: "100%" }} id='markInputSubmit' className=" mt-0" htmlType="submit" icon={<SearchOutlined />} >
                                Search
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <br />
                <Skeleton loading={loading1} paragraph={{ rows: 10 }} />

                <br />
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                {markInputTableData?.length > 0 &&
                    <Row style={{ display: markInputTableData?.length > 0 ? 'block' : 'none' }}>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                            <span style={{ fontWeight: "bold", fontSize: 16 }}>Student List:</span>
                            <Table
                                columns={columns}
                                data={markInputTableData}
                                updateMyData={updateMyData}
                                skipPageReset={skipPageReset}
                            />
                        </Col>
                        <br />
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                            <Button type="primary" className="mt-0 pull-right" onClick={(e) => saveMarkInputValue(e)} icon={<SaveOutlined />} >Save</Button>
                        </Col>
                    </Row>
                }
            </>
        </Card>
    )
}