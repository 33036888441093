import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker, Radio } from 'antd';
import moneyReceiptTemplateOne from "../../../../../assets/images/money-receipt-template-1.png";
import moneyReceiptTemplateTwo from "../../../../../assets/images/money-receipt-template-2.png";
import moneyReceiptTemplateThree from "../../../../../assets/images/money-receipt-template-4.png";
import moneyReceiptTemplateFive from "../../../../../assets/images/money-receipt-template-5.jpg";
import moneyReceiptTemplateSix from "../../../../../assets/images/money-receipt-template-6.jpg";
import moneyReceiptTemplateSeven from "../../../../../assets/images/money-receipt-template-7.jpg";
import moneyReceiptTemplateEight from "../../../../../assets/images/money-receipt-template-8.jpg";
import moneyReceiptTemplateTen from "../../../../../assets/images/money-receipt-template-10.png";
import moneyReceiptTemplateEleven from "../../../../../assets/images/money-receipt-template-11.png";
import moneyReceiptTemplateTwelve from "../../../../../assets/images/money-receipt-template-12.png";
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';


export default function MoneyReciptTemplate() {
    const fetchMoneyReciptTemplate = useStoreActions((state) => state.student.fetchMoneyReciptTemplate);
    const moneyReciptTemplateList = useStoreState((state) => state.student.moneyReciptTemplateList);
    const moneyRecipySaveTemplate = useStoreActions((state) => state.student.moneyRecipySaveTemplate);
    const [templateDefaultValue, setTemplateDefaultValue] = useState<any>();
    useEffect( () => {
        fetchMoneyReciptTemplate();
    }, []);

    useEffect(() => {
        setTemplateDefaultValue(moneyReciptTemplateList);
    }, [moneyReciptTemplateList])

    const templateSaveMethod = (val) => {
        setTemplateDefaultValue(val);
        moneyRecipySaveTemplate(val);        
    }
   
    return(
        <>
            {templateDefaultValue !== '' ? 
                <Card title="Student Money Recipt">
                    <Row>
                        <Col span={24}>
                            <Radio.Group size="large" style={{ width: "100%" }} className='cardTemplateWrapper' onChange={(e) =>  templateSaveMethod(e.target.value)}> 
                                <Row>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6}}>
                                        <Radio.Button value={101} className={templateDefaultValue == 101 ? 'ant-radio-button-wrapper-checked' : ''}><img style={{ width: "100%" }} src={moneyReceiptTemplateOne} alt="" />
                                        <div className='d-flex justify-content-center'>
                                            <strong>Template 1</strong>
                                        </div>
                                        </Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6}}>
                                        <Radio.Button value={102} className={templateDefaultValue == 102 ? 'ant-radio-button-wrapper-checked' : ''}><img style={{ width: "100%" }} src={moneyReceiptTemplateTwo} alt="" />
                                        <div className='d-flex justify-content-center'>
                                            <strong>Template 2</strong>
                                        </div>
                                        </Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6}}>
                                        <Radio.Button value="103" className={templateDefaultValue == 103 ? 'ant-radio-button-wrapper-checked' : ''}><img style={{ width: "100%" }} src={moneyReceiptTemplateThree} alt="" />
                                        <div className='d-flex justify-content-center'>
                                            <strong>Template 3</strong>
                                        </div>
                                        </Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6}}>
                                        <Radio.Button value="104" className={templateDefaultValue == 104 ? 'ant-radio-button-wrapper-checked' : ''}><img style={{ width: "100%" }} src={moneyReceiptTemplateEight} alt="" />
                                        <div className='d-flex justify-content-center'>
                                            <strong>Template 4</strong>
                                        </div>
                                        </Radio.Button>
                                    </Col>                                
                                </Row>
                                

                                <Row>

                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6}}>
                                        <Radio.Button value="105" className={templateDefaultValue == 105 ? 'ant-radio-button-wrapper-checked' : ''}><img style={{ width: "100%" }} src={moneyReceiptTemplateFive} alt="" />
                                        <div className='d-flex justify-content-center'>
                                            <strong>Template 5</strong>
                                        </div>
                                        </Radio.Button>
                                </Col>

                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6}}>
                                        <Radio.Button value="106" className={templateDefaultValue == 106 ? 'ant-radio-button-wrapper-checked' : ''}><img style={{ width: "100%" }} src={moneyReceiptTemplateSix} alt="" />
                                        <div className='d-flex justify-content-center'>
                                            <strong>Template 6</strong>
                                        </div>
                                        </Radio.Button>
                                </Col>

                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6}}>
                                        <Radio.Button value="107" className={templateDefaultValue == 107 ? 'ant-radio-button-wrapper-checked' : ''}><img style={{ width: "100%" }} src={moneyReceiptTemplateSeven} alt="" />
                                        <div className='d-flex justify-content-center'>
                                            <strong>Template 7</strong>
                                        </div>
                                        </Radio.Button>
                                </Col>

                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6}}>
                                        <Radio.Button value="108" className={templateDefaultValue == 108 ? 'ant-radio-button-wrapper-checked' : ''}><img style={{ width: "100%" }} src={moneyReceiptTemplateEight} alt="" />
                                        <div className='d-flex justify-content-center'>
                                            <strong>Template 8</strong>
                                        </div>
                                        </Radio.Button>
                                </Col>

                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6}}>
                                        <Radio.Button value="109" className={templateDefaultValue == 109 ? 'ant-radio-button-wrapper-checked' : ''}><img style={{ width: "100%" }} src={moneyReceiptTemplateSeven} alt="" />
                                        <div className='d-flex justify-content-center'>
                                            <strong>Template 9 (Bangla)</strong>
                                        </div>
                                        </Radio.Button>
                                </Col>

                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6}}>
                                        <Radio.Button value="110" className={templateDefaultValue == 110 ? 'ant-radio-button-wrapper-checked' : ''}><img style={{ width: "100%" }} src={moneyReceiptTemplateTen} alt="" />
                                        <div className='d-flex justify-content-center'>
                                            <strong>Template 10</strong>
                                        </div>
                                        </Radio.Button>
                                </Col>

                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6}}>
                                        <Radio.Button value="111" className={templateDefaultValue == 111 ? 'ant-radio-button-wrapper-checked' : ''}><img style={{ width: "100%" }} src={moneyReceiptTemplateEleven} alt="" />
                                        <div className='d-flex justify-content-center'>
                                            <strong>Template 11</strong>
                                        </div>
                                        </Radio.Button>
                                </Col>

                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6}}>
                                        <Radio.Button value="112" className={templateDefaultValue == 112 ? 'ant-radio-button-wrapper-checked' : ''}><img style={{ width: "100%" }} src={moneyReceiptTemplateTwelve} alt="" />
                                        <div className='d-flex justify-content-center'>
                                            <strong>Template 12</strong>
                                        </div>
                                        </Radio.Button>
                                </Col>

                                
                                </Row>
                            </Radio.Group>
                        </Col>
                    </Row>
                </Card>
            : ''}
        </>
    )
}