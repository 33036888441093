import { Button, Card, Col, DatePicker, Form, Popconfirm, Row, Skeleton, Space, Table, Tooltip } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { DeleteOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import TableView from "../../../../contents/AntTableResponsive";
import ReactExport from "react-export-excel";
import { SelectLedgerDFPSMulti } from '../../../select/SelectLedgerDFPSMulti';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import Text from 'antd/lib/typography/Text';
import { SelectFeeHead } from '../../../select/SelectFeeHead';
import { Excel } from 'antd-table-saveas-excel';
import { SelectFeeSubHeadMultiple } from '../../../select/SelectFeeSubHeadMultiple';
import { SelectExamByClass } from '../../../select/SelectExamByClass';
import { SelectClass } from '../../../select/SelectClass';





export default function AdmitCardDownloadCondition(props) {
    const admitCardDownloadConditionList = useStoreState((state) => state.exam.admitCardDownloadConditionList);
    const fetchadmitCardDownloadConditionList = useStoreActions((state) => state.exam.fetchadmitCardDownloadConditionList);
    const saveAdmitCardDownloadCondition = useStoreActions((state) => state.exam.saveAdmitCardDownloadCondition);
    const deleteAdmitCardDownloadCondition = useStoreActions((state) => state.exam.deleteAdmitCardDownloadCondition);
    const loading = useStoreState((state) => state.exam.loading);
    const itemListFetch = useStoreActions((state) => state.saccount.fetchfeeSubHeadConfigurationList);
    const fetchExamByClassListUrl = useStoreActions((state) => state.exam.fetchExamListByClassAndClassIdroutine);
    const [admitCardForm] = Form.useForm();

    const submiDataForm = (value) => {
        let payload = {
            "academicYear": value?.academicYear,
            "classId": value?.classId,
            "examId": value?.examId,
            "feeHeadId": value?.feeHeadId,
            "feeSubHeadIds": value?.feeSubHeadIds
        }
        saveAdmitCardDownloadCondition(payload);
        setTimeout(() => {
            fetchData();
        }, 1000);
    };

    const columns = [

        {
            title: 'Academic Year',
            dataIndex: 'academicYear',
            key: 'academicYear',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Class',
            dataIndex: 'className',
            key: 'className',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Exam',
            dataIndex: 'examName',
            key: 'examName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Fee Head',
            dataIndex: 'feeHead',
            key: 'feeHead',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Fee Sub Head',
            dataIndex: 'feeSubHead',
            key: 'feeSubHead',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Action',
            dataIndex: 'conditionId',
            key: 'conditionId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (

                <Popconfirm
                    title="Are you sure to delete this?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => {
                        deleteAdmitCardDownloadCondition(record?.conditionId)
                        setTimeout(() => {
                            fetchData();
                        }, 1000);
                    }
                    }
                >
                    <Tooltip title="Delete" >
                        <Button danger icon={<DeleteOutlined />} />
                    </Tooltip>
                </Popconfirm>

            )
        }

    ];



    const [sh, setsh] = useState<any>([]);
    const [exId, setExid] = useState<any>(null);

    const fetchData = () => {
        let payload = {
            "academicYear": admitCardForm.getFieldValue('academicYear'),
            "classId": admitCardForm.getFieldValue('classId'),
            "examId": admitCardForm.getFieldValue('examId')
        }
        fetchadmitCardDownloadConditionList(payload);

    }



    return (
        <>
            <Card title="Admit Card Download Condition">
                <Form
                    layout="vertical"
                    onFinish={submiDataForm}
                    id="basic-info"
                    form={admitCardForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={1} xl={1}> </Col>



                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item
                                name="academicYear"
                                label="Academic Year:"
                                className="academicYear"
                                rules={[
                                    { required: true, message: "Please select year" },
                                ]}
                            >

                                <SelectAcademicYear onChange={(e) => { admitCardForm.setFieldsValue({ examId: null }); setExid(null) }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item
                                name="classId"
                                label="Class"
                                className="classId"
                                rules={[
                                    { required: true, message: "Please select class" },
                                ]}
                            >

                                <SelectClass onChange={(e) => { fetchExamByClassListUrl(e); admitCardForm.setFieldsValue({ examId: null }); setExid(null) }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item
                                name="examId"
                                label="Exam"
                                className="examId"
                                rules={[
                                    { required: true, message: "Please select exam" },
                                ]}
                            >

                                <SelectExamByClass selected={exId} onChange={(e) => { fetchData(); setExid(e); }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item
                                name="feeHeadId"
                                label="Fee Head"
                                className="feeHeadId"
                                rules={[
                                    { required: true, message: "Please select fee" },
                                ]}
                            >

                                <SelectFeeHead onChange={e => { itemListFetch(e); setsh([]); admitCardForm.setFieldsValue({ feeSubHeadIds: null }) }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item
                                name="feeSubHeadIds"
                                label="Fee Subhead"
                                className="feeSubHeadIds"
                                rules={[
                                    { required: true, message: "Please select fee subhead" },
                                ]}
                            >

                                <SelectFeeSubHeadMultiple selected={sh} onChange={e => setsh(e)} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                                    Save
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>
                {admitCardDownloadConditionList === null && < Skeleton loading={loading} paragraph={{ rows: 10 }} />}
                {admitCardDownloadConditionList?.length > 0 &&
                    <div >
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: columns,
                                dataSource: admitCardDownloadConditionList,
                                filterData: admitCardDownloadConditionList,
                                pagination: true,
                                bordered: true,
                                rowKey: "conditionId",

                            }}
                            mobileBreakPoint={768}

                        />

                    </div>
                }
            </Card>
        </>
    )
}
