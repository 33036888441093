import { DeleteOutlined, EditOutlined, EyeOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Descriptions, Form, Input, Modal, notification, Popconfirm, Row, Select, Skeleton, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import moment from 'moment';
const { Option } = Select;

export default function UpayPayBillProblem() {
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();

    const upayOfpsProblemView = useStoreState((state) => state.customerSupport.upayOfpsProblemView);
    const isofps = useStoreState((state) => state.customerSupport.isofps);
    const fetchupayOfpsProblemView = useStoreActions((state) => state.customerSupport.fetchupayOfpsProblemView);
 

    const loading = useStoreState((state) =>  state.ledger.loading);
    
    const [refTrnNo, setRefTrnNo] = useState<any>(null);

    const fetchcheckUpayPayBill = useStoreActions((state) => state.customerSupport.fetchcheckUpayPayBill);
    const solveUpayPayBillProblem = useStoreActions((state) => state.customerSupport.solveUpayPayBillProblem);
    

    const solveProbelm = (value) => {
        
        let postData = {
            "refTrnNo": refTrnNo,
        };
        
        solveUpayPayBillProblem(postData);
        setIsModalVisible(false);
        setTimeout(() => {
            setRefTrnNo(null)
            fetchupayOfpsProblemView(data);
        }, 1000);
        
    }

    const [data, setData] = useState<any>({});
    
    const formSubmit = (value) => {

        value.trnDate = moment(value?.trnDate).format("YYYY-MM-DD")
        fetchupayOfpsProblemView(value);
        setData(value);
    }

    useEffect(() => {
        if (isofps === true) {
            setIsModalVisible(true)
        }
    }, [isofps])

    const columns = [

        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Transaction Date',
            dataIndex: 'transactionDate',
            key: 'transactionDate',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Fee Amount',
            dataIndex: 'feesAmount',
            key: 'feesAmount',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Ref Trn No',
            dataIndex: 'refTrnNo',
            key: 'refTrnNo',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Upay Transaction Id',
            dataIndex: 'upayTransactionId',
            key: 'upayTransactionId',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Transaction Status',
            dataIndex: 'transactionStatus',
            key: 'transactionStatus',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Check',
            key: 'id',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Check Problem">
                        <Button type='primary' icon={<EyeOutlined />} onClick={() => {
                            setRefTrnNo(record.refTrnNo);
                            fetchcheckUpayPayBill(record.refTrnNo)
                            updateForm.setFieldsValue({
                                requestLogId: record.requestLogId,
                                transactionDate: record.transactionDate,
                                upayTransactionId: record.upayTransactionId,
                                customStudentId: record.customStudentId,
                                studentName: record.studentName,
                                transactionStatus: record.transactionStatus,
                                refTrnNo: record.refTrnNo,
                                feesAmount: record.feesAmount,
                            });



                        }}
                        />
                    </Tooltip>

                </Space>
            ),
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <>
            <Card title="Upay (Pay Bill)" >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="customStudentId"
                                label="Student Id"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input student id" },
                                ]}
                            >

                                <Input placeholder="Student Id" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="instituteId"
                                label="Institute Id"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input institute id" },
                                ]}
                            >

                                <Input placeholder="Institute Id" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="trnDate"
                                label="Tran. Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select tran date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>


                
                {upayOfpsProblemView?.length > 0 &&
                    <div className="datatable-responsive">
                        <Skeleton loading={loading} paragraph={{ rows: 2 }} />
                        <Table
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: upayOfpsProblemView,
                                filterData: upayOfpsProblemView,
                                pagination: false,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                       
                    </div>
                }
            </Card>


            <Modal
                title="Upay Bill Problem"
                visible={isModalVisible}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Solve"
                centered
            >
                <Form
                    layout="vertical"

                    onFinish={solveProbelm}
                    id="update"
                    form={updateForm}
                >
                    <Form.Item
                        name="requestLogId"
                        label="Wallet"
                        className="title-Text"
                    >
                        <Input  disabled/>
                    </Form.Item>

                    <Form.Item
                        name="transactionDate"
                        label="Transaction Date"
                        className="title-Text"
                    >
                        <Input  disabled/>
                    </Form.Item>

                    <Form.Item
                        name="upayTransactionId"
                        label="Transaction ID"
                        className="title-Text"
                    >
                        <Input  disabled/>
                    </Form.Item>

                    <Form.Item
                        name="customStudentId"
                        label="Student Id"
                        className="title-Text"
                    >
                        <Input  disabled/>
                    </Form.Item>

                    <Form.Item
                        name="studentName"
                        label="Student Name"
                        className="title-Text"
                    >
                        <Input  disabled/>
                    </Form.Item>

                    <Form.Item
                        name="transactionStatus"
                        label="Status"
                        className="title-Text"
                    >
                        <Input  disabled/>
                    </Form.Item>

                    <Form.Item
                        name="refTrnNo"
                        label="Ref Trn No"
                        className="title-Text"
                    >
                        <Input  disabled/>
                    </Form.Item>
                    
                    <Form.Item
                        name="feesAmount"
                        label="Amount"
                        className="title-Text"
                    >
                        <Input  disabled/>
                    </Form.Item>
                </Form>

            </Modal>

           
        </>
    )
}
