import { post, get, del, put, delBulk} from "../http";

export const jumpInstiuteUrl = (payload) => get('/institute/jump?instituteId=' + payload);
export const instituteListUrl = () => get('/institute/list/by/owner?instituteOwner=SDL');
export const fetchInstituteListForManagement = (payload) => post('/institute/list/for/management',payload);
export const instituteListUrlCore = () => get('/institute/billing/institute/core/list?instituteOwner=SDL');
export const instituteListPag = (payload) => get('/ofps/configuration/institute/list?numberOfpage='+payload.numberOfpage+'&pageSize='+payload.pageSize);
export const fetchofpsinstiuteListNoPag = () => get('/ofps/configuration/list');
export const fetchOfpsConfigurationListForManagement = (payload) => get('/ofps/configuration/list/for/management?instituteStatus='+payload.instituteStatus);
export const instituteListPag2 = (payload) => get('/ofps/configuration/institute/list?instituteName='+payload.instituteName+'&numberOfpage='+payload.numberOfpage);
export const ofpsConfigurationAmountUpdate = (payload) => post('/ofps/configuration/amount/update?configId='+payload.configId+'&serviceCharge='+payload.serviceCharge+'&step='+payload.step,payload);
export const fetchStudentCount = () => get('/institute/wise/student/count');
export const deActiveInstiuteUrl = (payload) => get('/institute/inactive?instituteId='+payload);
export const activeInstiuteUrl = (payload) => get('/institute/active?instituteId='+payload);
export const updateInstituteStatus = (payload) => get('/institute/update/status?instituteId='+payload.instituteId+'&instituteStatus='+payload.instituteStatus);
export const updateInstituteFormFillUpStatus = (payload) => get('/institute/form/fill-up/status/update?instituteId='+payload.instituteId+'&formFillUpStatus='+payload.formFillUpStatus);
export const instituteBillingCoreInfoUpdate = (payload) => post('/institute/billing/core/info/update', payload);
export const classGradeListUrl = (payload) => get('/exam/grade/list/by/class-id?classId=' + payload);
export const solveOfpsTransactionProblem = (payload) => post('/ofps-transaction/problem/solve', payload);
export const solveNewOfpsTransactionProblem = (payload) => post("/ofps-transaction/problem/solve/new?sessionToken="+payload);
export const solveOfpsTransactionProblemDoublePayment = (payload) => post('/ofps-transaction/problem/solve/double/payment', payload);
export const fetchofpsTransactionProblemCheck = (payload) => get('/ofps-transaction/problem/check?refTrnNo=' + payload);
export const fetchcheckUpayPayBill = (payload) => get('/upay/ofps/check/view?refTrnNo=' + payload);
export const fetchOfpsTransactionProblemCheckNew = (payload) => get('/ofps-transaction/problem/check/new?sessionToken=' + payload);
export const fetchofpsTransactionProblemView = (payload) => get("/ofps-transaction/problem/view?customStudentId=" + payload.customStudentId + "&instituteId=" + payload.instituteId + "&trnDate=" + payload.trnDate);
export const fetchupayOfpsProblemView = (payload) => get("/upay/ofps/student/problem/view?customStudentId=" + payload.customStudentId + "&instituteId=" + payload.instituteId + "&trnDate=" + payload.trnDate);
export const fetchmigrationChange = (payload) => get("/student/report/single/general/view/by/custom-studentid/institute-id?customStudentId=" + payload.customStudentId + "&instituteId=" + payload.instituteId );
export const fetchmigrationSave = (payload) => get("/student/migration/change?identificationId=" + payload.identificationId + "&migrationStatus=" + payload.migrationStatus );
export const instituteDataUpdateUrl = (payload) => put('/exam/grade/update/by/customer-support', payload)
export const gradeDelete = (payload) => del('/exam/grade/delete?gradeId=' + payload);
export const partnerInfoSaveUrl = (payload) => post('/partner/save', payload);
export const partnerInfoUpdateUrl = (payload) => post('/partner/update', payload);
export const partnerListUrl = () => get('/partner/list');
export const partnerInstituteList = () => get('/partner/info/institute/list');
export const goToPartnerInstitute = (payload) => get('/partner/info/goto/institute?instituteId='+payload.instituteId+'&partnerId='+payload.partnerId);
export const partnerDeleteUrl = (payload) => del('/partner/delete?partnerId='+payload);
export const institutePackageUpdate = (payload) => post('/institute/package/update?instituteId='+payload.instituteId+'&packageId='+payload.packageId);
export const instituteOfpsTypeUpdate = (payload) => post('/institute/ofps-type/update?instituteId='+payload.instituteId+'&ofpsType='+payload.ofpsType);

export const fetchOnlineAdmissionSpgProblemView = (payload) => get("/online-admission/transaction/problem/view?registrationId="+ payload.registrationId+"&instituteId="+payload.instituteId+"&trnDate=" + payload.trnDate);
export const solveOnlineAdmissionSpgProblem = (payload) => post('/online-admission/transaction/problem/solve', payload);
export const solveNewOnlineAdmissionSpgProblem = (payload) => get("/online-admission/transaction/problem/solve/new?sessionToken="+ payload);

export const fetchinstituteListForBilling = (payload) => post('/institute/billing/list/for/create', payload);
export const createInstituteBilling = (payload) => post('/institute/billing/create', payload);
export const createInstituteBillManual = (payload) => post('/institute/billing/create/manual', payload);
export const updateInstituteBillManual = (payload) => post('/institute/billing/update', payload);
export const fetchinstituteBillingList = (payload) => post('/institute/billing/list', payload);
export const updateInstituteBilling = (payload) => post('/institute/billing/update', payload);
export const deleteInstituteBilling = (payload) => del('/institute/billing/delete?billId='+payload.billId);
export const okInstituteBilling = (payload) => post('/institute/billing/do/ok', payload);

export const fetchSingleInstitutePayableBill = () => get('/institute/billing/single/un-paid/list');
export const fetchSingleInstitutePaidBill = (payload) => get('/institute/billing/single/paid/list?billYear='+payload.billYear);

export const fetchInstituteDateToDateBillCollectionManagementReport = (payload) => get('/institute/billing/report/collection/summary/for/management?fromDate='+payload.fromDate+'&toDate='+payload.toDate);
export const fetchInstituteDateToDateOfpsCollectionManagementReport = (payload) => get('/ofps/transaction/report/summary/for/management?fromDate='+payload.fromDate+'&toDate='+payload.toDate);

export const partnerInfos = () => get('/partner/info/list');
export const partnerInfoUpdate = (payload) => post('/partner/info/update',payload);
export const partnerInfoSave = (payload) => post('/partner/info/save',payload);
export const partnerInfoDelete = (payload) => del('/partner/info/delete?partnerId='+payload.partnerId);

export const partnerAssignSave = (payload) => post('/partner/assign/save',payload);

export const fetchbillCollection = (payload) => get('/institute/billing/report/month/wise/bill-collection?year='+payload);
export const fetchmonthWiseSpg = (payload) => get('/ofps/configuration/month/wise/spg/live?year='+payload);
export const fetchserviceCharge = (payload) => get('/ofps/transaction/report/month/wise/service-charge?year='+payload);
export const updateInstituteThana = (payload) => post('/institute/thana/update', payload);
export const fetchbkasOfpsTransactionConfigurationView = () => get('/bkash/ofps-transaction/configuration/view');
export const saveOfpsTransactionConfiguration = (payload) => post('/bkash/ofps-transaction/configuration/save',payload);
export const fetchStudentBkashOfpsProblemTransactionList = (payload) => get(`/bkash/ofps-transaction/problem/student/view?customStudentId=${payload.customStudentId}&instituteId=${payload.instituteId}&trnDate=${payload.trnDate}`);
export const fetchStudentUpayPgwProblemTransactionList = (payload) => get(`/upay/pgw-transaction/problem/student/view?customStudentId=${payload.customStudentId}&instituteId=${payload.instituteId}&trnDate=${payload.trnDate}`);
export const checkBkashOfpsProblemTransaction = (payload) => get('/bkash/ofps-transaction/problem/check?paymentId=' + payload);
export const solveBkashOfpsTransactionProblem = (payload) => post('/bkash/ofps-transaction/problem/solve?paymentId='+ payload.paymentId);
export const refundBkashOfpsTransaction = (payload) => post('/bkash/refund/transaction/do',payload);

export const checkUpayPgwsProblemTransaction = (payload) => get('/upay/pgw-transaction/problem/check?txnId=' + payload);
export const solveUpayPgwTransactionProblem = (payload) => post('/upay/pgw-transaction/problem/solve?upayInvoiceId='+ payload.upayInvoiceId);
export const solveUpayPayBillProblem = (payload) => post('/upay/ofps/problem/solve?refTrnNo='+ payload.refTrnNo);


export const fetchShebaSchoolInfo = () => get("/sheba/school/info/view");
export const saveShebaSchoolInfo = (payload) => post("/sheba/school/info/save", payload);

export const fetchZktecoDeviceList = () => get("/zkteco/device/list");
export const saveZktecoDevice = (payload) => post("/zkteco/device/save", payload);
export const updateZktecoDevice = (payload) => post("/zkteco/device/update", payload);
export const deleteZktecoDevice = (payload) => del("/zkteco/device/delete?recordId="+ payload);

export const fetchsanmasikclassList = () => get("/sanmasik/class/list");
export const createSanmasikClass = (payload) => post("/sanmasik/class/save", payload);
export const updateSanmasikClass = (payload) => post("/sanmasik/class/update", payload);
export const deleteSanmasikClass = (payload) => del("/sanmasik/class/delete?classId="+payload);

export const fetchsanmasikSubjectList = (payload) => get("/sanmasik/subject/list/by-class?classId="+payload);
export const fetchsanmasikSubjectListbyClassConfig = (payload) => get("/sanmasik/subject/list/by/class-configuration?classconfigId="+payload);
export const createsanmasikSubject = (payload) => post("/sanmasik/subject/save", payload);
export const updatesanmasikSubject = (payload) => post("/sanmasik/subject/update", payload);
export const deletesanmasikSubject = (payload) => del("/sanmasik/subject/delete?subjectId="+payload);

export const fetchsanmasikCompetencyList = (payload) => get("/sanmasik/competency/list/by-subject?subjectId="+payload);
export const createsanmasikCompetency = (payload) => post("/sanmasik/competency/save", payload);
export const updatesanmasikCompetency = (payload) => post("/sanmasik/competency/update", payload);
export const deletesanmasikCompetency = (payload) => del("/sanmasik/competency/delete?competencyId="+payload);

export const fetchstudentListForSavePiInfo = (payload) => post("/sanmasik/student/pi/input/student/list/for/save", payload);
export const saveSanmasikStudentPiInput = (payload) => post("/sanmasik/student/pi/input/save", payload);
export const studentListForUpdatePiInfo = (payload) => post("/student/pi/input/student/list/for/update", payload);

export const fetchpiInfoList = (payload) => get("/pi/info/list/by/subject?subjectId="+payload);
export const fetchpiInfoListbyChapter = (payload) => get("/pi/info/list/by/chapter?chapterId="+payload);
export const savepiInfo = (payload) => post("/pi/info/save", payload);
export const updatepiInfo = (payload) => post("/pi/info/update", payload);
export const deletepiInfo = (payload) => del("/pi/info/delete?configId="+payload);

export const fetchchapterInfoList = (payload) => get("/chapter/info/list/by/subject?subjectId="+payload);
export const saveChapterInfo = (payload) => post("/chapter/info/save", payload);
export const updateChapterInfo = (payload) => post("/chapter/info/update", payload);
export const deleteChapterInfo = (payload) => del("/chapter/info/delete?chpterId="+payload);


export const fetchInstituteClassSanmasikclassConfigurationList = () => get("/institute-class/sanmasik-class/configuration/list");
export const saveInstituteClassSanmasikclassConfiguration = (payload) => post("/institute-class/sanmasik-class/configuration/save",payload);
export const deleteInstituteClassSanmasikClassConfiguration = (payload) => del("/institute-class/sanmasik-class/configuration/delete?configId="+payload);

export const fetchinstituteCount = () => get("/institute/count");
export const fetchinstituteTransactionstatus = () => get("/institute/spg/trnsaction/status");
export const fetchpartnerWiseInstituteCount = () => get("/partner/wise/institute/count");
export const fetchinstituteCreateList = (payload) => get('/institute/create/list?fromDate='+payload.fromDate+'&toDate='+payload.toDate);
export const fetchpartnerWiseSpgTransaction = (payload) => get('/partner/wise/spg/transaction?fromDate='+payload.fromDate+'&toDate='+payload.toDate);



